.bg-gradient-x-white .card-header, .bg-gradient-x-white .card-footer, .bg-gradient-y-white .card-header, .bg-gradient-y-white .card-footer, .bg-gradient-directional-white .card-header, .bg-gradient-directional-white .card-footer, .bg-gradient-radial-white .card-header, .bg-gradient-radial-white .card-footer, .bg-gradient-striped-white .card-header, .bg-gradient-striped-white .card-footer, .bg-gradient-x2-white .card-header, .bg-gradient-x2-white .card-footer, .bg-gradient-y2-white .card-header, .bg-gradient-y2-white .card-footer {
  background-color : transparent;
}

.bg-gradient-x-black .card-header, .bg-gradient-x-black .card-footer, .bg-gradient-y-black .card-header, .bg-gradient-y-black .card-footer, .bg-gradient-directional-black .card-header, .bg-gradient-directional-black .card-footer, .bg-gradient-radial-black .card-header, .bg-gradient-radial-black .card-footer, .bg-gradient-striped-black .card-header, .bg-gradient-striped-black .card-footer, .bg-gradient-x2-black .card-header, .bg-gradient-x2-black .card-footer, .bg-gradient-y2-black .card-header, .bg-gradient-y2-black .card-footer {
  background-color : transparent;
}

.bg-gradient-x-primary {
  background-image : -webkit-gradient(linear, left top, right top, from(#3A44E1), to(#9298EF));
  background-image : -webkit-linear-gradient(left, #3A44E1 0%, #9298EF 100%);
  background-image :    -moz-linear-gradient(left, #3A44E1 0%, #9298EF 100%);
  background-image :      -o-linear-gradient(left, #3A44E1 0%, #9298EF 100%);
  background-image :         linear-gradient(to right, #3A44E1 0%, #9298EF 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-primary {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#3A44E1), to(#9298EF));
  background-image : -webkit-linear-gradient(top, #3A44E1 0%, #9298EF 100%);
  background-image :    -moz-linear-gradient(top, #3A44E1 0%, #9298EF 100%);
  background-image :      -o-linear-gradient(top, #3A44E1 0%, #9298EF 100%);
  background-image :         linear-gradient(to bottom, #3A44E1 0%, #9298EF 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-primary {
  background-image : -webkit-linear-gradient(45deg, #3A44E1, #9298EF);
  background-image :    -moz-linear-gradient(45deg, #3A44E1, #9298EF);
  background-image :      -o-linear-gradient(45deg, #3A44E1, #9298EF);
  background-image :         linear-gradient(45deg, #3A44E1, #9298EF);
  background-repeat : repeat-x;
}

.bg-gradient-x2-primary {
  background-image : -webkit-gradient(linear, left top, right top, from(#9298EF), color-stop(50%, #666EE8), to(#A9ADF2));
  background-image : -webkit-linear-gradient(left, #9298EF, #666EE8 50%, #A9ADF2);
  background-image :    -moz-linear-gradient(left, #9298EF, #666EE8 50%, #A9ADF2);
  background-image :      -o-linear-gradient(left, #9298EF, #666EE8 50%, #A9ADF2);
  background-image :         linear-gradient(to right, #9298EF, #666EE8 50%, #A9ADF2);
  background-repeat : no-repeat;
}

.bg-gradient-y2-primary {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#9298EF), color-stop(50%, #666EE8), to(#A9ADF2));
  background-image : -webkit-linear-gradient(#9298EF, #666EE8 50%, #A9ADF2);
  background-image :    -moz-linear-gradient(#9298EF, #666EE8 50%, #A9ADF2);
  background-image :      -o-linear-gradient(#9298EF, #666EE8 50%, #A9ADF2);
  background-image :         linear-gradient(#9298EF, #666EE8 50%, #A9ADF2);
  background-repeat : no-repeat;
}

.bg-gradient-radial-primary {
  background-image : -webkit-radial-gradient(circle, #3A44E1, #9298EF);
  background-image :    -moz-radial-gradient(circle, #3A44E1, #9298EF);
  background-image :      -o-radial-gradient(circle, #3A44E1, #9298EF);
  background-image :         radial-gradient(circle, #3A44E1, #9298EF);
  background-repeat : no-repeat;
}

.bg-gradient-striped-primary {
  background-image : -webkit-linear-gradient(45deg, #A9ADF2 25%, transparent 25%, transparent 50%, #A9ADF2 50%, #A9ADF2 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #A9ADF2 25%, transparent 25%, transparent 50%, #A9ADF2 50%, #A9ADF2 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #A9ADF2 25%, transparent 25%, transparent 50%, #A9ADF2 50%, #A9ADF2 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #A9ADF2 25%, transparent 25%, transparent 50%, #A9ADF2 50%, #A9ADF2 75%, transparent 75%, transparent);
}

.bg-gradient-x-primary .card-header, .bg-gradient-x-primary .card-footer, .bg-gradient-y-primary .card-header, .bg-gradient-y-primary .card-footer, .bg-gradient-directional-primary .card-header, .bg-gradient-directional-primary .card-footer, .bg-gradient-radial-primary .card-header, .bg-gradient-radial-primary .card-footer, .bg-gradient-striped-primary .card-header, .bg-gradient-striped-primary .card-footer, .bg-gradient-x2-primary .card-header, .bg-gradient-x2-primary .card-footer, .bg-gradient-y2-primary .card-header, .bg-gradient-y2-primary .card-footer {
  background-color : transparent;
}

.bg-gradient-x-secondary {
  background-image : -webkit-gradient(linear, left top, right top, from(#545766), to(#85899B));
  background-image : -webkit-linear-gradient(left, #545766 0%, #85899B 100%);
  background-image :    -moz-linear-gradient(left, #545766 0%, #85899B 100%);
  background-image :      -o-linear-gradient(left, #545766 0%, #85899B 100%);
  background-image :         linear-gradient(to right, #545766 0%, #85899B 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-secondary {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#545766), to(#85899B));
  background-image : -webkit-linear-gradient(top, #545766 0%, #85899B 100%);
  background-image :    -moz-linear-gradient(top, #545766 0%, #85899B 100%);
  background-image :      -o-linear-gradient(top, #545766 0%, #85899B 100%);
  background-image :         linear-gradient(to bottom, #545766 0%, #85899B 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-secondary {
  background-image : -webkit-linear-gradient(45deg, #545766, #85899B);
  background-image :    -moz-linear-gradient(45deg, #545766, #85899B);
  background-image :      -o-linear-gradient(45deg, #545766, #85899B);
  background-image :         linear-gradient(45deg, #545766, #85899B);
  background-repeat : repeat-x;
}

.bg-gradient-x2-secondary {
  background-image : -webkit-gradient(linear, left top, right top, from(#85899B), color-stop(50%, #6B6F82), to(#9397A6));
  background-image : -webkit-linear-gradient(left, #85899B, #6B6F82 50%, #9397A6);
  background-image :    -moz-linear-gradient(left, #85899B, #6B6F82 50%, #9397A6);
  background-image :      -o-linear-gradient(left, #85899B, #6B6F82 50%, #9397A6);
  background-image :         linear-gradient(to right, #85899B, #6B6F82 50%, #9397A6);
  background-repeat : no-repeat;
}

.bg-gradient-y2-secondary {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#85899B), color-stop(50%, #6B6F82), to(#9397A6));
  background-image : -webkit-linear-gradient(#85899B, #6B6F82 50%, #9397A6);
  background-image :    -moz-linear-gradient(#85899B, #6B6F82 50%, #9397A6);
  background-image :      -o-linear-gradient(#85899B, #6B6F82 50%, #9397A6);
  background-image :         linear-gradient(#85899B, #6B6F82 50%, #9397A6);
  background-repeat : no-repeat;
}

.bg-gradient-radial-secondary {
  background-image : -webkit-radial-gradient(circle, #545766, #85899B);
  background-image :    -moz-radial-gradient(circle, #545766, #85899B);
  background-image :      -o-radial-gradient(circle, #545766, #85899B);
  background-image :         radial-gradient(circle, #545766, #85899B);
  background-repeat : no-repeat;
}

.bg-gradient-striped-secondary {
  background-image : -webkit-linear-gradient(45deg, #9397A6 25%, transparent 25%, transparent 50%, #9397A6 50%, #9397A6 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #9397A6 25%, transparent 25%, transparent 50%, #9397A6 50%, #9397A6 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #9397A6 25%, transparent 25%, transparent 50%, #9397A6 50%, #9397A6 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #9397A6 25%, transparent 25%, transparent 50%, #9397A6 50%, #9397A6 75%, transparent 75%, transparent);
}

.bg-gradient-x-secondary .card-header, .bg-gradient-x-secondary .card-footer, .bg-gradient-y-secondary .card-header, .bg-gradient-y-secondary .card-footer, .bg-gradient-directional-secondary .card-header, .bg-gradient-directional-secondary .card-footer, .bg-gradient-radial-secondary .card-header, .bg-gradient-radial-secondary .card-footer, .bg-gradient-striped-secondary .card-header, .bg-gradient-striped-secondary .card-footer, .bg-gradient-x2-secondary .card-header, .bg-gradient-x2-secondary .card-footer, .bg-gradient-y2-secondary .card-header, .bg-gradient-y2-secondary .card-footer {
  background-color : transparent;
}

.bg-gradient-x-success {
  background-image : -webkit-gradient(linear, left top, right top, from(#20A576), to(#4EDDAA));
  background-image : -webkit-linear-gradient(left, #20A576 0%, #4EDDAA 100%);
  background-image :    -moz-linear-gradient(left, #20A576 0%, #4EDDAA 100%);
  background-image :      -o-linear-gradient(left, #20A576 0%, #4EDDAA 100%);
  background-image :         linear-gradient(to right, #20A576 0%, #4EDDAA 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-success {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#20A576), to(#4EDDAA));
  background-image : -webkit-linear-gradient(top, #20A576 0%, #4EDDAA 100%);
  background-image :    -moz-linear-gradient(top, #20A576 0%, #4EDDAA 100%);
  background-image :      -o-linear-gradient(top, #20A576 0%, #4EDDAA 100%);
  background-image :         linear-gradient(to bottom, #20A576 0%, #4EDDAA 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-success {
  background-image : -webkit-linear-gradient(45deg, #20A576, #4EDDAA);
  background-image :    -moz-linear-gradient(45deg, #20A576, #4EDDAA);
  background-image :      -o-linear-gradient(45deg, #20A576, #4EDDAA);
  background-image :         linear-gradient(45deg, #20A576, #4EDDAA);
  background-repeat : repeat-x;
}

.bg-gradient-x2-success {
  background-image : -webkit-gradient(linear, left top, right top, from(#4EDDAA), color-stop(50%, #28D094), to(#63E1B4));
  background-image : -webkit-linear-gradient(left, #4EDDAA, #28D094 50%, #63E1B4);
  background-image :    -moz-linear-gradient(left, #4EDDAA, #28D094 50%, #63E1B4);
  background-image :      -o-linear-gradient(left, #4EDDAA, #28D094 50%, #63E1B4);
  background-image :         linear-gradient(to right, #4EDDAA, #28D094 50%, #63E1B4);
  background-repeat : no-repeat;
}

.bg-gradient-y2-success {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#4EDDAA), color-stop(50%, #28D094), to(#63E1B4));
  background-image : -webkit-linear-gradient(#4EDDAA, #28D094 50%, #63E1B4);
  background-image :    -moz-linear-gradient(#4EDDAA, #28D094 50%, #63E1B4);
  background-image :      -o-linear-gradient(#4EDDAA, #28D094 50%, #63E1B4);
  background-image :         linear-gradient(#4EDDAA, #28D094 50%, #63E1B4);
  background-repeat : no-repeat;
}

.bg-gradient-radial-success {
  background-image : -webkit-radial-gradient(circle, #20A576, #4EDDAA);
  background-image :    -moz-radial-gradient(circle, #20A576, #4EDDAA);
  background-image :      -o-radial-gradient(circle, #20A576, #4EDDAA);
  background-image :         radial-gradient(circle, #20A576, #4EDDAA);
  background-repeat : no-repeat;
}

.bg-gradient-striped-success {
  background-image : -webkit-linear-gradient(45deg, #63E1B4 25%, transparent 25%, transparent 50%, #63E1B4 50%, #63E1B4 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #63E1B4 25%, transparent 25%, transparent 50%, #63E1B4 50%, #63E1B4 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #63E1B4 25%, transparent 25%, transparent 50%, #63E1B4 50%, #63E1B4 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #63E1B4 25%, transparent 25%, transparent 50%, #63E1B4 50%, #63E1B4 75%, transparent 75%, transparent);
}

.bg-gradient-x-success .card-header, .bg-gradient-x-success .card-footer, .bg-gradient-y-success .card-header, .bg-gradient-y-success .card-footer, .bg-gradient-directional-success .card-header, .bg-gradient-directional-success .card-footer, .bg-gradient-radial-success .card-header, .bg-gradient-radial-success .card-footer, .bg-gradient-striped-success .card-header, .bg-gradient-striped-success .card-footer, .bg-gradient-x2-success .card-header, .bg-gradient-x2-success .card-footer, .bg-gradient-y2-success .card-header, .bg-gradient-y2-success .card-footer {
  background-color : transparent;
}

.bg-gradient-x-info {
  background-image : -webkit-gradient(linear, left top, right top, from(#0C84D1), to(#4EB4F5));
  background-image : -webkit-linear-gradient(left, #0C84D1 0%, #4EB4F5 100%);
  background-image :    -moz-linear-gradient(left, #0C84D1 0%, #4EB4F5 100%);
  background-image :      -o-linear-gradient(left, #0C84D1 0%, #4EB4F5 100%);
  background-image :         linear-gradient(to right, #0C84D1 0%, #4EB4F5 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-info {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#0C84D1), to(#4EB4F5));
  background-image : -webkit-linear-gradient(top, #0C84D1 0%, #4EB4F5 100%);
  background-image :    -moz-linear-gradient(top, #0C84D1 0%, #4EB4F5 100%);
  background-image :      -o-linear-gradient(top, #0C84D1 0%, #4EB4F5 100%);
  background-image :         linear-gradient(to bottom, #0C84D1 0%, #4EB4F5 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-info {
  background-image : -webkit-linear-gradient(45deg, #0C84D1, #4EB4F5);
  background-image :    -moz-linear-gradient(45deg, #0C84D1, #4EB4F5);
  background-image :      -o-linear-gradient(45deg, #0C84D1, #4EB4F5);
  background-image :         linear-gradient(45deg, #0C84D1, #4EB4F5);
  background-repeat : repeat-x;
}

.bg-gradient-x2-info {
  background-image : -webkit-gradient(linear, left top, right top, from(#4EB4F5), color-stop(50%, #1E9FF2), to(#66BEF6));
  background-image : -webkit-linear-gradient(left, #4EB4F5, #1E9FF2 50%, #66BEF6);
  background-image :    -moz-linear-gradient(left, #4EB4F5, #1E9FF2 50%, #66BEF6);
  background-image :      -o-linear-gradient(left, #4EB4F5, #1E9FF2 50%, #66BEF6);
  background-image :         linear-gradient(to right, #4EB4F5, #1E9FF2 50%, #66BEF6);
  background-repeat : no-repeat;
}

.bg-gradient-y2-info {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#4EB4F5), color-stop(50%, #1E9FF2), to(#66BEF6));
  background-image : -webkit-linear-gradient(#4EB4F5, #1E9FF2 50%, #66BEF6);
  background-image :    -moz-linear-gradient(#4EB4F5, #1E9FF2 50%, #66BEF6);
  background-image :      -o-linear-gradient(#4EB4F5, #1E9FF2 50%, #66BEF6);
  background-image :         linear-gradient(#4EB4F5, #1E9FF2 50%, #66BEF6);
  background-repeat : no-repeat;
}

.bg-gradient-radial-info {
  background-image : -webkit-radial-gradient(circle, #0C84D1, #4EB4F5);
  background-image :    -moz-radial-gradient(circle, #0C84D1, #4EB4F5);
  background-image :      -o-radial-gradient(circle, #0C84D1, #4EB4F5);
  background-image :         radial-gradient(circle, #0C84D1, #4EB4F5);
  background-repeat : no-repeat;
}

.bg-gradient-striped-info {
  background-image : -webkit-linear-gradient(45deg, #66BEF6 25%, transparent 25%, transparent 50%, #66BEF6 50%, #66BEF6 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #66BEF6 25%, transparent 25%, transparent 50%, #66BEF6 50%, #66BEF6 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #66BEF6 25%, transparent 25%, transparent 50%, #66BEF6 50%, #66BEF6 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #66BEF6 25%, transparent 25%, transparent 50%, #66BEF6 50%, #66BEF6 75%, transparent 75%, transparent);
}

.bg-gradient-x-info .card-header, .bg-gradient-x-info .card-footer, .bg-gradient-y-info .card-header, .bg-gradient-y-info .card-footer, .bg-gradient-directional-info .card-header, .bg-gradient-directional-info .card-footer, .bg-gradient-radial-info .card-header, .bg-gradient-radial-info .card-footer, .bg-gradient-striped-info .card-header, .bg-gradient-striped-info .card-footer, .bg-gradient-x2-info .card-header, .bg-gradient-x2-info .card-footer, .bg-gradient-y2-info .card-header, .bg-gradient-y2-info .card-footer {
  background-color : transparent;
}

.bg-gradient-x-warning {
  background-image : -webkit-gradient(linear, left top, right top, from(#FF7216), to(#FFB07C));
  background-image : -webkit-linear-gradient(left, #FF7216 0%, #FFB07C 100%);
  background-image :    -moz-linear-gradient(left, #FF7216 0%, #FFB07C 100%);
  background-image :      -o-linear-gradient(left, #FF7216 0%, #FFB07C 100%);
  background-image :         linear-gradient(to right, #FF7216 0%, #FFB07C 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-warning {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#FF7216), to(#FFB07C));
  background-image : -webkit-linear-gradient(top, #FF7216 0%, #FFB07C 100%);
  background-image :    -moz-linear-gradient(top, #FF7216 0%, #FFB07C 100%);
  background-image :      -o-linear-gradient(top, #FF7216 0%, #FFB07C 100%);
  background-image :         linear-gradient(to bottom, #FF7216 0%, #FFB07C 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-warning {
  background-image : -webkit-linear-gradient(45deg, #FF7216, #FFB07C);
  background-image :    -moz-linear-gradient(45deg, #FF7216, #FFB07C);
  background-image :      -o-linear-gradient(45deg, #FF7216, #FFB07C);
  background-image :         linear-gradient(45deg, #FF7216, #FFB07C);
  background-repeat : repeat-x;
}

.bg-gradient-x2-warning {
  background-image : -webkit-gradient(linear, left top, right top, from(#FFB07C), color-stop(50%, #FF9149), to(#FFBF96));
  background-image : -webkit-linear-gradient(left, #FFB07C, #FF9149 50%, #FFBF96);
  background-image :    -moz-linear-gradient(left, #FFB07C, #FF9149 50%, #FFBF96);
  background-image :      -o-linear-gradient(left, #FFB07C, #FF9149 50%, #FFBF96);
  background-image :         linear-gradient(to right, #FFB07C, #FF9149 50%, #FFBF96);
  background-repeat : no-repeat;
}

.bg-gradient-y2-warning {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#FFB07C), color-stop(50%, #FF9149), to(#FFBF96));
  background-image : -webkit-linear-gradient(#FFB07C, #FF9149 50%, #FFBF96);
  background-image :    -moz-linear-gradient(#FFB07C, #FF9149 50%, #FFBF96);
  background-image :      -o-linear-gradient(#FFB07C, #FF9149 50%, #FFBF96);
  background-image :         linear-gradient(#FFB07C, #FF9149 50%, #FFBF96);
  background-repeat : no-repeat;
}

.bg-gradient-radial-warning {
  background-image : -webkit-radial-gradient(circle, #FF7216, #FFB07C);
  background-image :    -moz-radial-gradient(circle, #FF7216, #FFB07C);
  background-image :      -o-radial-gradient(circle, #FF7216, #FFB07C);
  background-image :         radial-gradient(circle, #FF7216, #FFB07C);
  background-repeat : no-repeat;
}

.bg-gradient-striped-warning {
  background-image : -webkit-linear-gradient(45deg, #FFBF96 25%, transparent 25%, transparent 50%, #FFBF96 50%, #FFBF96 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #FFBF96 25%, transparent 25%, transparent 50%, #FFBF96 50%, #FFBF96 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #FFBF96 25%, transparent 25%, transparent 50%, #FFBF96 50%, #FFBF96 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #FFBF96 25%, transparent 25%, transparent 50%, #FFBF96 50%, #FFBF96 75%, transparent 75%, transparent);
}

.bg-gradient-x-warning .card-header, .bg-gradient-x-warning .card-footer, .bg-gradient-y-warning .card-header, .bg-gradient-y-warning .card-footer, .bg-gradient-directional-warning .card-header, .bg-gradient-directional-warning .card-footer, .bg-gradient-radial-warning .card-header, .bg-gradient-radial-warning .card-footer, .bg-gradient-striped-warning .card-header, .bg-gradient-striped-warning .card-footer, .bg-gradient-x2-warning .card-header, .bg-gradient-x2-warning .card-footer, .bg-gradient-y2-warning .card-header, .bg-gradient-y2-warning .card-footer {
  background-color : transparent;
}

.bg-gradient-x-danger {
  background-image : -webkit-gradient(linear, left top, right top, from(#FF1635), to(#FF7C8D));
  background-image : -webkit-linear-gradient(left, #FF1635 0%, #FF7C8D 100%);
  background-image :    -moz-linear-gradient(left, #FF1635 0%, #FF7C8D 100%);
  background-image :      -o-linear-gradient(left, #FF1635 0%, #FF7C8D 100%);
  background-image :         linear-gradient(to right, #FF1635 0%, #FF7C8D 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-danger {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#FF1635), to(#FF7C8D));
  background-image : -webkit-linear-gradient(top, #FF1635 0%, #FF7C8D 100%);
  background-image :    -moz-linear-gradient(top, #FF1635 0%, #FF7C8D 100%);
  background-image :      -o-linear-gradient(top, #FF1635 0%, #FF7C8D 100%);
  background-image :         linear-gradient(to bottom, #FF1635 0%, #FF7C8D 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-danger {
  background-image : -webkit-linear-gradient(45deg, #FF1635, #FF7C8D);
  background-image :    -moz-linear-gradient(45deg, #FF1635, #FF7C8D);
  background-image :      -o-linear-gradient(45deg, #FF1635, #FF7C8D);
  background-image :         linear-gradient(45deg, #FF1635, #FF7C8D);
  background-repeat : repeat-x;
}

.bg-gradient-x2-danger {
  background-image : -webkit-gradient(linear, left top, right top, from(#FF7C8D), color-stop(50%, #FF4961), to(#FF96A3));
  background-image : -webkit-linear-gradient(left, #FF7C8D, #FF4961 50%, #FF96A3);
  background-image :    -moz-linear-gradient(left, #FF7C8D, #FF4961 50%, #FF96A3);
  background-image :      -o-linear-gradient(left, #FF7C8D, #FF4961 50%, #FF96A3);
  background-image :         linear-gradient(to right, #FF7C8D, #FF4961 50%, #FF96A3);
  background-repeat : no-repeat;
}

.bg-gradient-y2-danger {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#FF7C8D), color-stop(50%, #FF4961), to(#FF96A3));
  background-image : -webkit-linear-gradient(#FF7C8D, #FF4961 50%, #FF96A3);
  background-image :    -moz-linear-gradient(#FF7C8D, #FF4961 50%, #FF96A3);
  background-image :      -o-linear-gradient(#FF7C8D, #FF4961 50%, #FF96A3);
  background-image :         linear-gradient(#FF7C8D, #FF4961 50%, #FF96A3);
  background-repeat : no-repeat;
}

.bg-gradient-radial-danger {
  background-image : -webkit-radial-gradient(circle, #FF1635, #FF7C8D);
  background-image :    -moz-radial-gradient(circle, #FF1635, #FF7C8D);
  background-image :      -o-radial-gradient(circle, #FF1635, #FF7C8D);
  background-image :         radial-gradient(circle, #FF1635, #FF7C8D);
  background-repeat : no-repeat;
}

.bg-gradient-striped-danger {
  background-image : -webkit-linear-gradient(45deg, #FF96A3 25%, transparent 25%, transparent 50%, #FF96A3 50%, #FF96A3 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #FF96A3 25%, transparent 25%, transparent 50%, #FF96A3 50%, #FF96A3 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #FF96A3 25%, transparent 25%, transparent 50%, #FF96A3 50%, #FF96A3 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #FF96A3 25%, transparent 25%, transparent 50%, #FF96A3 50%, #FF96A3 75%, transparent 75%, transparent);
}

.bg-gradient-x-danger .card-header, .bg-gradient-x-danger .card-footer, .bg-gradient-y-danger .card-header, .bg-gradient-y-danger .card-footer, .bg-gradient-directional-danger .card-header, .bg-gradient-directional-danger .card-footer, .bg-gradient-radial-danger .card-header, .bg-gradient-radial-danger .card-footer, .bg-gradient-striped-danger .card-header, .bg-gradient-striped-danger .card-footer, .bg-gradient-x2-danger .card-header, .bg-gradient-x2-danger .card-footer, .bg-gradient-y2-danger .card-header, .bg-gradient-y2-danger .card-footer {
  background-color : transparent;
}

.bg-gradient-x-light .card-header, .bg-gradient-x-light .card-footer, .bg-gradient-y-light .card-header, .bg-gradient-y-light .card-footer, .bg-gradient-directional-light .card-header, .bg-gradient-directional-light .card-footer, .bg-gradient-radial-light .card-header, .bg-gradient-radial-light .card-footer, .bg-gradient-striped-light .card-header, .bg-gradient-striped-light .card-footer, .bg-gradient-x2-light .card-header, .bg-gradient-x2-light .card-footer, .bg-gradient-y2-light .card-header, .bg-gradient-y2-light .card-footer {
  background-color : transparent;
}

.bg-gradient-x-dark .card-header, .bg-gradient-x-dark .card-footer, .bg-gradient-y-dark .card-header, .bg-gradient-y-dark .card-footer, .bg-gradient-directional-dark .card-header, .bg-gradient-directional-dark .card-footer, .bg-gradient-radial-dark .card-header, .bg-gradient-radial-dark .card-footer, .bg-gradient-striped-dark .card-header, .bg-gradient-striped-dark .card-footer, .bg-gradient-x2-dark .card-header, .bg-gradient-x2-dark .card-footer, .bg-gradient-y2-dark .card-header, .bg-gradient-y2-dark .card-footer {
  background-color : transparent;
}

.bg-gradient-x-red {
  background-image : -webkit-gradient(linear, left top, right top, from(#D32F2F), to(#E57373));
  background-image : -webkit-linear-gradient(left, #D32F2F 0%, #E57373 100%);
  background-image :    -moz-linear-gradient(left, #D32F2F 0%, #E57373 100%);
  background-image :      -o-linear-gradient(left, #D32F2F 0%, #E57373 100%);
  background-image :         linear-gradient(to right, #D32F2F 0%, #E57373 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-red {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#D32F2F), to(#E57373));
  background-image : -webkit-linear-gradient(top, #D32F2F 0%, #E57373 100%);
  background-image :    -moz-linear-gradient(top, #D32F2F 0%, #E57373 100%);
  background-image :      -o-linear-gradient(top, #D32F2F 0%, #E57373 100%);
  background-image :         linear-gradient(to bottom, #D32F2F 0%, #E57373 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-red {
  background-image : -webkit-linear-gradient(45deg, #D32F2F, #E57373);
  background-image :    -moz-linear-gradient(45deg, #D32F2F, #E57373);
  background-image :      -o-linear-gradient(45deg, #D32F2F, #E57373);
  background-image :         linear-gradient(45deg, #D32F2F, #E57373);
  background-repeat : repeat-x;
}

.bg-gradient-x2-red {
  background-image : -webkit-gradient(linear, left top, right top, from(#E57373), color-stop(50%, #F44336), to(#EF9A9A));
  background-image : -webkit-linear-gradient(left, #E57373, #F44336 50%, #EF9A9A);
  background-image :    -moz-linear-gradient(left, #E57373, #F44336 50%, #EF9A9A);
  background-image :      -o-linear-gradient(left, #E57373, #F44336 50%, #EF9A9A);
  background-image :         linear-gradient(to right, #E57373, #F44336 50%, #EF9A9A);
  background-repeat : no-repeat;
}

.bg-gradient-y2-red {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#E57373), color-stop(50%, #F44336), to(#EF9A9A));
  background-image : -webkit-linear-gradient(#E57373, #F44336 50%, #EF9A9A);
  background-image :    -moz-linear-gradient(#E57373, #F44336 50%, #EF9A9A);
  background-image :      -o-linear-gradient(#E57373, #F44336 50%, #EF9A9A);
  background-image :         linear-gradient(#E57373, #F44336 50%, #EF9A9A);
  background-repeat : no-repeat;
}

.bg-gradient-radial-red {
  background-image : -webkit-radial-gradient(circle, #D32F2F, #E57373);
  background-image :    -moz-radial-gradient(circle, #D32F2F, #E57373);
  background-image :      -o-radial-gradient(circle, #D32F2F, #E57373);
  background-image :         radial-gradient(circle, #D32F2F, #E57373);
  background-repeat : no-repeat;
}

.bg-gradient-striped-red {
  background-image : -webkit-linear-gradient(45deg, #EF9A9A 25%, transparent 25%, transparent 50%, #EF9A9A 50%, #EF9A9A 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #EF9A9A 25%, transparent 25%, transparent 50%, #EF9A9A 50%, #EF9A9A 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #EF9A9A 25%, transparent 25%, transparent 50%, #EF9A9A 50%, #EF9A9A 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #EF9A9A 25%, transparent 25%, transparent 50%, #EF9A9A 50%, #EF9A9A 75%, transparent 75%, transparent);
}

.bg-gradient-x-red .card-header, .bg-gradient-x-red .card-footer, .bg-gradient-y-red .card-header, .bg-gradient-y-red .card-footer, .bg-gradient-directional-red .card-header, .bg-gradient-directional-red .card-footer, .bg-gradient-radial-red .card-header, .bg-gradient-radial-red .card-footer, .bg-gradient-striped-red .card-header, .bg-gradient-striped-red .card-footer, .bg-gradient-x2-red .card-header, .bg-gradient-x2-red .card-footer, .bg-gradient-y2-red .card-header, .bg-gradient-y2-red .card-footer {
  background-color : transparent;
}

.bg-gradient-x-pink {
  background-image : -webkit-gradient(linear, left top, right top, from(#C2185B), to(#F06292));
  background-image : -webkit-linear-gradient(left, #C2185B 0%, #F06292 100%);
  background-image :    -moz-linear-gradient(left, #C2185B 0%, #F06292 100%);
  background-image :      -o-linear-gradient(left, #C2185B 0%, #F06292 100%);
  background-image :         linear-gradient(to right, #C2185B 0%, #F06292 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-pink {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#C2185B), to(#F06292));
  background-image : -webkit-linear-gradient(top, #C2185B 0%, #F06292 100%);
  background-image :    -moz-linear-gradient(top, #C2185B 0%, #F06292 100%);
  background-image :      -o-linear-gradient(top, #C2185B 0%, #F06292 100%);
  background-image :         linear-gradient(to bottom, #C2185B 0%, #F06292 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-pink {
  background-image : -webkit-linear-gradient(45deg, #C2185B, #F06292);
  background-image :    -moz-linear-gradient(45deg, #C2185B, #F06292);
  background-image :      -o-linear-gradient(45deg, #C2185B, #F06292);
  background-image :         linear-gradient(45deg, #C2185B, #F06292);
  background-repeat : repeat-x;
}

.bg-gradient-x2-pink {
  background-image : -webkit-gradient(linear, left top, right top, from(#F06292), color-stop(50%, #E91E63), to(#F48FB1));
  background-image : -webkit-linear-gradient(left, #F06292, #E91E63 50%, #F48FB1);
  background-image :    -moz-linear-gradient(left, #F06292, #E91E63 50%, #F48FB1);
  background-image :      -o-linear-gradient(left, #F06292, #E91E63 50%, #F48FB1);
  background-image :         linear-gradient(to right, #F06292, #E91E63 50%, #F48FB1);
  background-repeat : no-repeat;
}

.bg-gradient-y2-pink {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#F06292), color-stop(50%, #E91E63), to(#F48FB1));
  background-image : -webkit-linear-gradient(#F06292, #E91E63 50%, #F48FB1);
  background-image :    -moz-linear-gradient(#F06292, #E91E63 50%, #F48FB1);
  background-image :      -o-linear-gradient(#F06292, #E91E63 50%, #F48FB1);
  background-image :         linear-gradient(#F06292, #E91E63 50%, #F48FB1);
  background-repeat : no-repeat;
}

.bg-gradient-radial-pink {
  background-image : -webkit-radial-gradient(circle, #C2185B, #F06292);
  background-image :    -moz-radial-gradient(circle, #C2185B, #F06292);
  background-image :      -o-radial-gradient(circle, #C2185B, #F06292);
  background-image :         radial-gradient(circle, #C2185B, #F06292);
  background-repeat : no-repeat;
}

.bg-gradient-striped-pink {
  background-image : -webkit-linear-gradient(45deg, #F48FB1 25%, transparent 25%, transparent 50%, #F48FB1 50%, #F48FB1 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #F48FB1 25%, transparent 25%, transparent 50%, #F48FB1 50%, #F48FB1 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #F48FB1 25%, transparent 25%, transparent 50%, #F48FB1 50%, #F48FB1 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #F48FB1 25%, transparent 25%, transparent 50%, #F48FB1 50%, #F48FB1 75%, transparent 75%, transparent);
}

.bg-gradient-x-pink .card-header, .bg-gradient-x-pink .card-footer, .bg-gradient-y-pink .card-header, .bg-gradient-y-pink .card-footer, .bg-gradient-directional-pink .card-header, .bg-gradient-directional-pink .card-footer, .bg-gradient-radial-pink .card-header, .bg-gradient-radial-pink .card-footer, .bg-gradient-striped-pink .card-header, .bg-gradient-striped-pink .card-footer, .bg-gradient-x2-pink .card-header, .bg-gradient-x2-pink .card-footer, .bg-gradient-y2-pink .card-header, .bg-gradient-y2-pink .card-footer {
  background-color : transparent;
}

.bg-gradient-x-purple {
  background-image : -webkit-gradient(linear, left top, right top, from(#7B1FA2), to(#BA68C8));
  background-image : -webkit-linear-gradient(left, #7B1FA2 0%, #BA68C8 100%);
  background-image :    -moz-linear-gradient(left, #7B1FA2 0%, #BA68C8 100%);
  background-image :      -o-linear-gradient(left, #7B1FA2 0%, #BA68C8 100%);
  background-image :         linear-gradient(to right, #7B1FA2 0%, #BA68C8 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-purple {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#7B1FA2), to(#BA68C8));
  background-image : -webkit-linear-gradient(top, #7B1FA2 0%, #BA68C8 100%);
  background-image :    -moz-linear-gradient(top, #7B1FA2 0%, #BA68C8 100%);
  background-image :      -o-linear-gradient(top, #7B1FA2 0%, #BA68C8 100%);
  background-image :         linear-gradient(to bottom, #7B1FA2 0%, #BA68C8 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-purple {
  background-image : -webkit-linear-gradient(45deg, #7B1FA2, #BA68C8);
  background-image :    -moz-linear-gradient(45deg, #7B1FA2, #BA68C8);
  background-image :      -o-linear-gradient(45deg, #7B1FA2, #BA68C8);
  background-image :         linear-gradient(45deg, #7B1FA2, #BA68C8);
  background-repeat : repeat-x;
}

.bg-gradient-x2-purple {
  background-image : -webkit-gradient(linear, left top, right top, from(#BA68C8), color-stop(50%, #9C27B0), to(#CE93D8));
  background-image : -webkit-linear-gradient(left, #BA68C8, #9C27B0 50%, #CE93D8);
  background-image :    -moz-linear-gradient(left, #BA68C8, #9C27B0 50%, #CE93D8);
  background-image :      -o-linear-gradient(left, #BA68C8, #9C27B0 50%, #CE93D8);
  background-image :         linear-gradient(to right, #BA68C8, #9C27B0 50%, #CE93D8);
  background-repeat : no-repeat;
}

.bg-gradient-y2-purple {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#BA68C8), color-stop(50%, #9C27B0), to(#CE93D8));
  background-image : -webkit-linear-gradient(#BA68C8, #9C27B0 50%, #CE93D8);
  background-image :    -moz-linear-gradient(#BA68C8, #9C27B0 50%, #CE93D8);
  background-image :      -o-linear-gradient(#BA68C8, #9C27B0 50%, #CE93D8);
  background-image :         linear-gradient(#BA68C8, #9C27B0 50%, #CE93D8);
  background-repeat : no-repeat;
}

.bg-gradient-radial-purple {
  background-image : -webkit-radial-gradient(circle, #7B1FA2, #BA68C8);
  background-image :    -moz-radial-gradient(circle, #7B1FA2, #BA68C8);
  background-image :      -o-radial-gradient(circle, #7B1FA2, #BA68C8);
  background-image :         radial-gradient(circle, #7B1FA2, #BA68C8);
  background-repeat : no-repeat;
}

.bg-gradient-striped-purple {
  background-image : -webkit-linear-gradient(45deg, #CE93D8 25%, transparent 25%, transparent 50%, #CE93D8 50%, #CE93D8 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #CE93D8 25%, transparent 25%, transparent 50%, #CE93D8 50%, #CE93D8 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #CE93D8 25%, transparent 25%, transparent 50%, #CE93D8 50%, #CE93D8 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #CE93D8 25%, transparent 25%, transparent 50%, #CE93D8 50%, #CE93D8 75%, transparent 75%, transparent);
}

.bg-gradient-x-purple .card-header, .bg-gradient-x-purple .card-footer, .bg-gradient-y-purple .card-header, .bg-gradient-y-purple .card-footer, .bg-gradient-directional-purple .card-header, .bg-gradient-directional-purple .card-footer, .bg-gradient-radial-purple .card-header, .bg-gradient-radial-purple .card-footer, .bg-gradient-striped-purple .card-header, .bg-gradient-striped-purple .card-footer, .bg-gradient-x2-purple .card-header, .bg-gradient-x2-purple .card-footer, .bg-gradient-y2-purple .card-header, .bg-gradient-y2-purple .card-footer {
  background-color : transparent;
}

.bg-gradient-x-blue {
  background-image : -webkit-gradient(linear, left top, right top, from(#1976D2), to(#64B5F6));
  background-image : -webkit-linear-gradient(left, #1976D2 0%, #64B5F6 100%);
  background-image :    -moz-linear-gradient(left, #1976D2 0%, #64B5F6 100%);
  background-image :      -o-linear-gradient(left, #1976D2 0%, #64B5F6 100%);
  background-image :         linear-gradient(to right, #1976D2 0%, #64B5F6 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-blue {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#1976D2), to(#64B5F6));
  background-image : -webkit-linear-gradient(top, #1976D2 0%, #64B5F6 100%);
  background-image :    -moz-linear-gradient(top, #1976D2 0%, #64B5F6 100%);
  background-image :      -o-linear-gradient(top, #1976D2 0%, #64B5F6 100%);
  background-image :         linear-gradient(to bottom, #1976D2 0%, #64B5F6 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-blue {
  background-image : -webkit-linear-gradient(45deg, #1976D2, #64B5F6);
  background-image :    -moz-linear-gradient(45deg, #1976D2, #64B5F6);
  background-image :      -o-linear-gradient(45deg, #1976D2, #64B5F6);
  background-image :         linear-gradient(45deg, #1976D2, #64B5F6);
  background-repeat : repeat-x;
}

.bg-gradient-x2-blue {
  background-image : -webkit-gradient(linear, left top, right top, from(#64B5F6), color-stop(50%, #2196F3), to(#90CAF9));
  background-image : -webkit-linear-gradient(left, #64B5F6, #2196F3 50%, #90CAF9);
  background-image :    -moz-linear-gradient(left, #64B5F6, #2196F3 50%, #90CAF9);
  background-image :      -o-linear-gradient(left, #64B5F6, #2196F3 50%, #90CAF9);
  background-image :         linear-gradient(to right, #64B5F6, #2196F3 50%, #90CAF9);
  background-repeat : no-repeat;
}

.bg-gradient-y2-blue {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#64B5F6), color-stop(50%, #2196F3), to(#90CAF9));
  background-image : -webkit-linear-gradient(#64B5F6, #2196F3 50%, #90CAF9);
  background-image :    -moz-linear-gradient(#64B5F6, #2196F3 50%, #90CAF9);
  background-image :      -o-linear-gradient(#64B5F6, #2196F3 50%, #90CAF9);
  background-image :         linear-gradient(#64B5F6, #2196F3 50%, #90CAF9);
  background-repeat : no-repeat;
}

.bg-gradient-radial-blue {
  background-image : -webkit-radial-gradient(circle, #1976D2, #64B5F6);
  background-image :    -moz-radial-gradient(circle, #1976D2, #64B5F6);
  background-image :      -o-radial-gradient(circle, #1976D2, #64B5F6);
  background-image :         radial-gradient(circle, #1976D2, #64B5F6);
  background-repeat : no-repeat;
}

.bg-gradient-striped-blue {
  background-image : -webkit-linear-gradient(45deg, #90CAF9 25%, transparent 25%, transparent 50%, #90CAF9 50%, #90CAF9 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #90CAF9 25%, transparent 25%, transparent 50%, #90CAF9 50%, #90CAF9 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #90CAF9 25%, transparent 25%, transparent 50%, #90CAF9 50%, #90CAF9 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #90CAF9 25%, transparent 25%, transparent 50%, #90CAF9 50%, #90CAF9 75%, transparent 75%, transparent);
}

.bg-gradient-x-blue .card-header, .bg-gradient-x-blue .card-footer, .bg-gradient-y-blue .card-header, .bg-gradient-y-blue .card-footer, .bg-gradient-directional-blue .card-header, .bg-gradient-directional-blue .card-footer, .bg-gradient-radial-blue .card-header, .bg-gradient-radial-blue .card-footer, .bg-gradient-striped-blue .card-header, .bg-gradient-striped-blue .card-footer, .bg-gradient-x2-blue .card-header, .bg-gradient-x2-blue .card-footer, .bg-gradient-y2-blue .card-header, .bg-gradient-y2-blue .card-footer {
  background-color : transparent;
}

.bg-gradient-x-cyan {
  background-image : -webkit-gradient(linear, left top, right top, from(#0097A7), to(#4DD0E1));
  background-image : -webkit-linear-gradient(left, #0097A7 0%, #4DD0E1 100%);
  background-image :    -moz-linear-gradient(left, #0097A7 0%, #4DD0E1 100%);
  background-image :      -o-linear-gradient(left, #0097A7 0%, #4DD0E1 100%);
  background-image :         linear-gradient(to right, #0097A7 0%, #4DD0E1 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-cyan {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#0097A7), to(#4DD0E1));
  background-image : -webkit-linear-gradient(top, #0097A7 0%, #4DD0E1 100%);
  background-image :    -moz-linear-gradient(top, #0097A7 0%, #4DD0E1 100%);
  background-image :      -o-linear-gradient(top, #0097A7 0%, #4DD0E1 100%);
  background-image :         linear-gradient(to bottom, #0097A7 0%, #4DD0E1 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-cyan {
  background-image : -webkit-linear-gradient(45deg, #0097A7, #4DD0E1);
  background-image :    -moz-linear-gradient(45deg, #0097A7, #4DD0E1);
  background-image :      -o-linear-gradient(45deg, #0097A7, #4DD0E1);
  background-image :         linear-gradient(45deg, #0097A7, #4DD0E1);
  background-repeat : repeat-x;
}

.bg-gradient-x2-cyan {
  background-image : -webkit-gradient(linear, left top, right top, from(#4DD0E1), color-stop(50%, #00BCD4), to(#80DEEA));
  background-image : -webkit-linear-gradient(left, #4DD0E1, #00BCD4 50%, #80DEEA);
  background-image :    -moz-linear-gradient(left, #4DD0E1, #00BCD4 50%, #80DEEA);
  background-image :      -o-linear-gradient(left, #4DD0E1, #00BCD4 50%, #80DEEA);
  background-image :         linear-gradient(to right, #4DD0E1, #00BCD4 50%, #80DEEA);
  background-repeat : no-repeat;
}

.bg-gradient-y2-cyan {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#4DD0E1), color-stop(50%, #00BCD4), to(#80DEEA));
  background-image : -webkit-linear-gradient(#4DD0E1, #00BCD4 50%, #80DEEA);
  background-image :    -moz-linear-gradient(#4DD0E1, #00BCD4 50%, #80DEEA);
  background-image :      -o-linear-gradient(#4DD0E1, #00BCD4 50%, #80DEEA);
  background-image :         linear-gradient(#4DD0E1, #00BCD4 50%, #80DEEA);
  background-repeat : no-repeat;
}

.bg-gradient-radial-cyan {
  background-image : -webkit-radial-gradient(circle, #0097A7, #4DD0E1);
  background-image :    -moz-radial-gradient(circle, #0097A7, #4DD0E1);
  background-image :      -o-radial-gradient(circle, #0097A7, #4DD0E1);
  background-image :         radial-gradient(circle, #0097A7, #4DD0E1);
  background-repeat : no-repeat;
}

.bg-gradient-striped-cyan {
  background-image : -webkit-linear-gradient(45deg, #80DEEA 25%, transparent 25%, transparent 50%, #80DEEA 50%, #80DEEA 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #80DEEA 25%, transparent 25%, transparent 50%, #80DEEA 50%, #80DEEA 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #80DEEA 25%, transparent 25%, transparent 50%, #80DEEA 50%, #80DEEA 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #80DEEA 25%, transparent 25%, transparent 50%, #80DEEA 50%, #80DEEA 75%, transparent 75%, transparent);
}

.bg-gradient-x-cyan .card-header, .bg-gradient-x-cyan .card-footer, .bg-gradient-y-cyan .card-header, .bg-gradient-y-cyan .card-footer, .bg-gradient-directional-cyan .card-header, .bg-gradient-directional-cyan .card-footer, .bg-gradient-radial-cyan .card-header, .bg-gradient-radial-cyan .card-footer, .bg-gradient-striped-cyan .card-header, .bg-gradient-striped-cyan .card-footer, .bg-gradient-x2-cyan .card-header, .bg-gradient-x2-cyan .card-footer, .bg-gradient-y2-cyan .card-header, .bg-gradient-y2-cyan .card-footer {
  background-color : transparent;
}

.bg-gradient-x-teal {
  background-image : -webkit-gradient(linear, left top, right top, from(#00796B), to(#4DB6AC));
  background-image : -webkit-linear-gradient(left, #00796B 0%, #4DB6AC 100%);
  background-image :    -moz-linear-gradient(left, #00796B 0%, #4DB6AC 100%);
  background-image :      -o-linear-gradient(left, #00796B 0%, #4DB6AC 100%);
  background-image :         linear-gradient(to right, #00796B 0%, #4DB6AC 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-teal {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#00796B), to(#4DB6AC));
  background-image : -webkit-linear-gradient(top, #00796B 0%, #4DB6AC 100%);
  background-image :    -moz-linear-gradient(top, #00796B 0%, #4DB6AC 100%);
  background-image :      -o-linear-gradient(top, #00796B 0%, #4DB6AC 100%);
  background-image :         linear-gradient(to bottom, #00796B 0%, #4DB6AC 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-teal {
  background-image : -webkit-linear-gradient(45deg, #00796B, #4DB6AC);
  background-image :    -moz-linear-gradient(45deg, #00796B, #4DB6AC);
  background-image :      -o-linear-gradient(45deg, #00796B, #4DB6AC);
  background-image :         linear-gradient(45deg, #00796B, #4DB6AC);
  background-repeat : repeat-x;
}

.bg-gradient-x2-teal {
  background-image : -webkit-gradient(linear, left top, right top, from(#4DB6AC), color-stop(50%, #009688), to(#80CBC4));
  background-image : -webkit-linear-gradient(left, #4DB6AC, #009688 50%, #80CBC4);
  background-image :    -moz-linear-gradient(left, #4DB6AC, #009688 50%, #80CBC4);
  background-image :      -o-linear-gradient(left, #4DB6AC, #009688 50%, #80CBC4);
  background-image :         linear-gradient(to right, #4DB6AC, #009688 50%, #80CBC4);
  background-repeat : no-repeat;
}

.bg-gradient-y2-teal {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#4DB6AC), color-stop(50%, #009688), to(#80CBC4));
  background-image : -webkit-linear-gradient(#4DB6AC, #009688 50%, #80CBC4);
  background-image :    -moz-linear-gradient(#4DB6AC, #009688 50%, #80CBC4);
  background-image :      -o-linear-gradient(#4DB6AC, #009688 50%, #80CBC4);
  background-image :         linear-gradient(#4DB6AC, #009688 50%, #80CBC4);
  background-repeat : no-repeat;
}

.bg-gradient-radial-teal {
  background-image : -webkit-radial-gradient(circle, #00796B, #4DB6AC);
  background-image :    -moz-radial-gradient(circle, #00796B, #4DB6AC);
  background-image :      -o-radial-gradient(circle, #00796B, #4DB6AC);
  background-image :         radial-gradient(circle, #00796B, #4DB6AC);
  background-repeat : no-repeat;
}

.bg-gradient-striped-teal {
  background-image : -webkit-linear-gradient(45deg, #80CBC4 25%, transparent 25%, transparent 50%, #80CBC4 50%, #80CBC4 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #80CBC4 25%, transparent 25%, transparent 50%, #80CBC4 50%, #80CBC4 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #80CBC4 25%, transparent 25%, transparent 50%, #80CBC4 50%, #80CBC4 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #80CBC4 25%, transparent 25%, transparent 50%, #80CBC4 50%, #80CBC4 75%, transparent 75%, transparent);
}

.bg-gradient-x-teal .card-header, .bg-gradient-x-teal .card-footer, .bg-gradient-y-teal .card-header, .bg-gradient-y-teal .card-footer, .bg-gradient-directional-teal .card-header, .bg-gradient-directional-teal .card-footer, .bg-gradient-radial-teal .card-header, .bg-gradient-radial-teal .card-footer, .bg-gradient-striped-teal .card-header, .bg-gradient-striped-teal .card-footer, .bg-gradient-x2-teal .card-header, .bg-gradient-x2-teal .card-footer, .bg-gradient-y2-teal .card-header, .bg-gradient-y2-teal .card-footer {
  background-color : transparent;
}

.bg-gradient-x-yellow {
  background-image : -webkit-gradient(linear, left top, right top, from(#FBC02D), to(#FFF176));
  background-image : -webkit-linear-gradient(left, #FBC02D 0%, #FFF176 100%);
  background-image :    -moz-linear-gradient(left, #FBC02D 0%, #FFF176 100%);
  background-image :      -o-linear-gradient(left, #FBC02D 0%, #FFF176 100%);
  background-image :         linear-gradient(to right, #FBC02D 0%, #FFF176 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-yellow {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#FBC02D), to(#FFF176));
  background-image : -webkit-linear-gradient(top, #FBC02D 0%, #FFF176 100%);
  background-image :    -moz-linear-gradient(top, #FBC02D 0%, #FFF176 100%);
  background-image :      -o-linear-gradient(top, #FBC02D 0%, #FFF176 100%);
  background-image :         linear-gradient(to bottom, #FBC02D 0%, #FFF176 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-yellow {
  background-image : -webkit-linear-gradient(45deg, #FBC02D, #FFF176);
  background-image :    -moz-linear-gradient(45deg, #FBC02D, #FFF176);
  background-image :      -o-linear-gradient(45deg, #FBC02D, #FFF176);
  background-image :         linear-gradient(45deg, #FBC02D, #FFF176);
  background-repeat : repeat-x;
}

.bg-gradient-x2-yellow {
  background-image : -webkit-gradient(linear, left top, right top, from(#FFF176), color-stop(50%, #FFEB3B), to(#FFF59D));
  background-image : -webkit-linear-gradient(left, #FFF176, #FFEB3B 50%, #FFF59D);
  background-image :    -moz-linear-gradient(left, #FFF176, #FFEB3B 50%, #FFF59D);
  background-image :      -o-linear-gradient(left, #FFF176, #FFEB3B 50%, #FFF59D);
  background-image :         linear-gradient(to right, #FFF176, #FFEB3B 50%, #FFF59D);
  background-repeat : no-repeat;
}

.bg-gradient-y2-yellow {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#FFF176), color-stop(50%, #FFEB3B), to(#FFF59D));
  background-image : -webkit-linear-gradient(#FFF176, #FFEB3B 50%, #FFF59D);
  background-image :    -moz-linear-gradient(#FFF176, #FFEB3B 50%, #FFF59D);
  background-image :      -o-linear-gradient(#FFF176, #FFEB3B 50%, #FFF59D);
  background-image :         linear-gradient(#FFF176, #FFEB3B 50%, #FFF59D);
  background-repeat : no-repeat;
}

.bg-gradient-radial-yellow {
  background-image : -webkit-radial-gradient(circle, #FBC02D, #FFF176);
  background-image :    -moz-radial-gradient(circle, #FBC02D, #FFF176);
  background-image :      -o-radial-gradient(circle, #FBC02D, #FFF176);
  background-image :         radial-gradient(circle, #FBC02D, #FFF176);
  background-repeat : no-repeat;
}

.bg-gradient-striped-yellow {
  background-image : -webkit-linear-gradient(45deg, #FFF59D 25%, transparent 25%, transparent 50%, #FFF59D 50%, #FFF59D 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #FFF59D 25%, transparent 25%, transparent 50%, #FFF59D 50%, #FFF59D 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #FFF59D 25%, transparent 25%, transparent 50%, #FFF59D 50%, #FFF59D 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #FFF59D 25%, transparent 25%, transparent 50%, #FFF59D 50%, #FFF59D 75%, transparent 75%, transparent);
}

.bg-gradient-x-yellow .card-header, .bg-gradient-x-yellow .card-footer, .bg-gradient-y-yellow .card-header, .bg-gradient-y-yellow .card-footer, .bg-gradient-directional-yellow .card-header, .bg-gradient-directional-yellow .card-footer, .bg-gradient-radial-yellow .card-header, .bg-gradient-radial-yellow .card-footer, .bg-gradient-striped-yellow .card-header, .bg-gradient-striped-yellow .card-footer, .bg-gradient-x2-yellow .card-header, .bg-gradient-x2-yellow .card-footer, .bg-gradient-y2-yellow .card-header, .bg-gradient-y2-yellow .card-footer {
  background-color : transparent;
}

.bg-gradient-x-amber {
  background-image : -webkit-gradient(linear, left top, right top, from(#FFA000), to(#FFD54F));
  background-image : -webkit-linear-gradient(left, #FFA000 0%, #FFD54F 100%);
  background-image :    -moz-linear-gradient(left, #FFA000 0%, #FFD54F 100%);
  background-image :      -o-linear-gradient(left, #FFA000 0%, #FFD54F 100%);
  background-image :         linear-gradient(to right, #FFA000 0%, #FFD54F 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-amber {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#FFA000), to(#FFD54F));
  background-image : -webkit-linear-gradient(top, #FFA000 0%, #FFD54F 100%);
  background-image :    -moz-linear-gradient(top, #FFA000 0%, #FFD54F 100%);
  background-image :      -o-linear-gradient(top, #FFA000 0%, #FFD54F 100%);
  background-image :         linear-gradient(to bottom, #FFA000 0%, #FFD54F 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-amber {
  background-image : -webkit-linear-gradient(45deg, #FFA000, #FFD54F);
  background-image :    -moz-linear-gradient(45deg, #FFA000, #FFD54F);
  background-image :      -o-linear-gradient(45deg, #FFA000, #FFD54F);
  background-image :         linear-gradient(45deg, #FFA000, #FFD54F);
  background-repeat : repeat-x;
}

.bg-gradient-x2-amber {
  background-image : -webkit-gradient(linear, left top, right top, from(#FFD54F), color-stop(50%, #FFC107), to(#FFE082));
  background-image : -webkit-linear-gradient(left, #FFD54F, #FFC107 50%, #FFE082);
  background-image :    -moz-linear-gradient(left, #FFD54F, #FFC107 50%, #FFE082);
  background-image :      -o-linear-gradient(left, #FFD54F, #FFC107 50%, #FFE082);
  background-image :         linear-gradient(to right, #FFD54F, #FFC107 50%, #FFE082);
  background-repeat : no-repeat;
}

.bg-gradient-y2-amber {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#FFD54F), color-stop(50%, #FFC107), to(#FFE082));
  background-image : -webkit-linear-gradient(#FFD54F, #FFC107 50%, #FFE082);
  background-image :    -moz-linear-gradient(#FFD54F, #FFC107 50%, #FFE082);
  background-image :      -o-linear-gradient(#FFD54F, #FFC107 50%, #FFE082);
  background-image :         linear-gradient(#FFD54F, #FFC107 50%, #FFE082);
  background-repeat : no-repeat;
}

.bg-gradient-radial-amber {
  background-image : -webkit-radial-gradient(circle, #FFA000, #FFD54F);
  background-image :    -moz-radial-gradient(circle, #FFA000, #FFD54F);
  background-image :      -o-radial-gradient(circle, #FFA000, #FFD54F);
  background-image :         radial-gradient(circle, #FFA000, #FFD54F);
  background-repeat : no-repeat;
}

.bg-gradient-striped-amber {
  background-image : -webkit-linear-gradient(45deg, #FFE082 25%, transparent 25%, transparent 50%, #FFE082 50%, #FFE082 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #FFE082 25%, transparent 25%, transparent 50%, #FFE082 50%, #FFE082 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #FFE082 25%, transparent 25%, transparent 50%, #FFE082 50%, #FFE082 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #FFE082 25%, transparent 25%, transparent 50%, #FFE082 50%, #FFE082 75%, transparent 75%, transparent);
}

.bg-gradient-x-amber .card-header, .bg-gradient-x-amber .card-footer, .bg-gradient-y-amber .card-header, .bg-gradient-y-amber .card-footer, .bg-gradient-directional-amber .card-header, .bg-gradient-directional-amber .card-footer, .bg-gradient-radial-amber .card-header, .bg-gradient-radial-amber .card-footer, .bg-gradient-striped-amber .card-header, .bg-gradient-striped-amber .card-footer, .bg-gradient-x2-amber .card-header, .bg-gradient-x2-amber .card-footer, .bg-gradient-y2-amber .card-header, .bg-gradient-y2-amber .card-footer {
  background-color : transparent;
}

.bg-gradient-x-blue-grey {
  background-image : -webkit-gradient(linear, left top, right top, from(#455A64), to(#90A4AE));
  background-image : -webkit-linear-gradient(left, #455A64 0%, #90A4AE 100%);
  background-image :    -moz-linear-gradient(left, #455A64 0%, #90A4AE 100%);
  background-image :      -o-linear-gradient(left, #455A64 0%, #90A4AE 100%);
  background-image :         linear-gradient(to right, #455A64 0%, #90A4AE 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-blue-grey {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#455A64), to(#90A4AE));
  background-image : -webkit-linear-gradient(top, #455A64 0%, #90A4AE 100%);
  background-image :    -moz-linear-gradient(top, #455A64 0%, #90A4AE 100%);
  background-image :      -o-linear-gradient(top, #455A64 0%, #90A4AE 100%);
  background-image :         linear-gradient(to bottom, #455A64 0%, #90A4AE 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-blue-grey {
  background-image : -webkit-linear-gradient(45deg, #455A64, #90A4AE);
  background-image :    -moz-linear-gradient(45deg, #455A64, #90A4AE);
  background-image :      -o-linear-gradient(45deg, #455A64, #90A4AE);
  background-image :         linear-gradient(45deg, #455A64, #90A4AE);
  background-repeat : repeat-x;
}

.bg-gradient-x2-blue-grey {
  background-image : -webkit-gradient(linear, left top, right top, from(#90A4AE), color-stop(50%, #607D8B), to(#B0BEC5));
  background-image : -webkit-linear-gradient(left, #90A4AE, #607D8B 50%, #B0BEC5);
  background-image :    -moz-linear-gradient(left, #90A4AE, #607D8B 50%, #B0BEC5);
  background-image :      -o-linear-gradient(left, #90A4AE, #607D8B 50%, #B0BEC5);
  background-image :         linear-gradient(to right, #90A4AE, #607D8B 50%, #B0BEC5);
  background-repeat : no-repeat;
}

.bg-gradient-y2-blue-grey {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#90A4AE), color-stop(50%, #607D8B), to(#B0BEC5));
  background-image : -webkit-linear-gradient(#90A4AE, #607D8B 50%, #B0BEC5);
  background-image :    -moz-linear-gradient(#90A4AE, #607D8B 50%, #B0BEC5);
  background-image :      -o-linear-gradient(#90A4AE, #607D8B 50%, #B0BEC5);
  background-image :         linear-gradient(#90A4AE, #607D8B 50%, #B0BEC5);
  background-repeat : no-repeat;
}

.bg-gradient-radial-blue-grey {
  background-image : -webkit-radial-gradient(circle, #455A64, #90A4AE);
  background-image :    -moz-radial-gradient(circle, #455A64, #90A4AE);
  background-image :      -o-radial-gradient(circle, #455A64, #90A4AE);
  background-image :         radial-gradient(circle, #455A64, #90A4AE);
  background-repeat : no-repeat;
}

.bg-gradient-striped-blue-grey {
  background-image : -webkit-linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent);
}

.bg-gradient-x-blue-grey .card-header, .bg-gradient-x-blue-grey .card-footer, .bg-gradient-y-blue-grey .card-header, .bg-gradient-y-blue-grey .card-footer, .bg-gradient-directional-blue-grey .card-header, .bg-gradient-directional-blue-grey .card-footer, .bg-gradient-radial-blue-grey .card-header, .bg-gradient-radial-blue-grey .card-footer, .bg-gradient-striped-blue-grey .card-header, .bg-gradient-striped-blue-grey .card-footer, .bg-gradient-x2-blue-grey .card-header, .bg-gradient-x2-blue-grey .card-footer, .bg-gradient-y2-blue-grey .card-header, .bg-gradient-y2-blue-grey .card-footer {
  background-color : transparent;
}

.bg-gradient-x-grey-blue {
  background-image : -webkit-gradient(linear, left top, right top, from(#2C303B), to(#6F85AD));
  background-image : -webkit-linear-gradient(left, #2C303B 0%, #6F85AD 100%);
  background-image :    -moz-linear-gradient(left, #2C303B 0%, #6F85AD 100%);
  background-image :      -o-linear-gradient(left, #2C303B 0%, #6F85AD 100%);
  background-image :         linear-gradient(to right, #2C303B 0%, #6F85AD 100%);
  background-repeat : repeat-x;
}

.bg-gradient-y-grey-blue {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#2C303B), to(#6F85AD));
  background-image : -webkit-linear-gradient(top, #2C303B 0%, #6F85AD 100%);
  background-image :    -moz-linear-gradient(top, #2C303B 0%, #6F85AD 100%);
  background-image :      -o-linear-gradient(top, #2C303B 0%, #6F85AD 100%);
  background-image :         linear-gradient(to bottom, #2C303B 0%, #6F85AD 100%);
  background-repeat : repeat-x;
}

.bg-gradient-directional-grey-blue {
  background-image : -webkit-linear-gradient(45deg, #2C303B, #6F85AD);
  background-image :    -moz-linear-gradient(45deg, #2C303B, #6F85AD);
  background-image :      -o-linear-gradient(45deg, #2C303B, #6F85AD);
  background-image :         linear-gradient(45deg, #2C303B, #6F85AD);
  background-repeat : repeat-x;
}

.bg-gradient-x2-grey-blue {
  background-image : -webkit-gradient(linear, left top, right top, from(#6F85AD), color-stop(50%, #1B2942), to(#B0BEC5));
  background-image : -webkit-linear-gradient(left, #6F85AD, #1B2942 50%, #B0BEC5);
  background-image :    -moz-linear-gradient(left, #6F85AD, #1B2942 50%, #B0BEC5);
  background-image :      -o-linear-gradient(left, #6F85AD, #1B2942 50%, #B0BEC5);
  background-image :         linear-gradient(to right, #6F85AD, #1B2942 50%, #B0BEC5);
  background-repeat : no-repeat;
}

.bg-gradient-y2-grey-blue {
  background-image : -webkit-gradient(linear, left top, left bottom, from(#6F85AD), color-stop(50%, #1B2942), to(#B0BEC5));
  background-image : -webkit-linear-gradient(#6F85AD, #1B2942 50%, #B0BEC5);
  background-image :    -moz-linear-gradient(#6F85AD, #1B2942 50%, #B0BEC5);
  background-image :      -o-linear-gradient(#6F85AD, #1B2942 50%, #B0BEC5);
  background-image :         linear-gradient(#6F85AD, #1B2942 50%, #B0BEC5);
  background-repeat : no-repeat;
}

.bg-gradient-radial-grey-blue {
  background-image : -webkit-radial-gradient(circle, #2C303B, #6F85AD);
  background-image :    -moz-radial-gradient(circle, #2C303B, #6F85AD);
  background-image :      -o-radial-gradient(circle, #2C303B, #6F85AD);
  background-image :         radial-gradient(circle, #2C303B, #6F85AD);
  background-repeat : no-repeat;
}

.bg-gradient-striped-grey-blue {
  background-image : -webkit-linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent);
  background-image :    -moz-linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent);
  background-image :      -o-linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent);
  background-image :         linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent);
}

.bg-gradient-x-grey-blue .card-header, .bg-gradient-x-grey-blue .card-footer, .bg-gradient-y-grey-blue .card-header, .bg-gradient-y-grey-blue .card-footer, .bg-gradient-directional-grey-blue .card-header, .bg-gradient-directional-grey-blue .card-footer, .bg-gradient-radial-grey-blue .card-header, .bg-gradient-radial-grey-blue .card-footer, .bg-gradient-striped-grey-blue .card-header, .bg-gradient-striped-grey-blue .card-footer, .bg-gradient-x2-grey-blue .card-header, .bg-gradient-x2-grey-blue .card-footer, .bg-gradient-y2-grey-blue .card-header, .bg-gradient-y2-grey-blue .card-footer {
  background-color : transparent;
}