/* You can add global styles to this file, and also import other style files */
@import '~ng-wizard/themes/ng_wizard.min.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

/* @font-face {
    font-family: 'RenaultLife-Light';
    font-display: auto;
    src: url('./assets/fonts/RenaultLife-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'RenaultLife-Regular';
    font-display: auto;
    src: url('./assets/fonts/RenaultLife-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'RenaultLife-Bold';
    font-display: auto;
    src: url('./assets/fonts/RenaultLife-Bold.ttf') format('truetype');
}


@font-face {
    font-family: 'Read-Light';
    font-display: auto;
    src: url('./assets/fonts/Read-Light_V1100.ttf') format('truetype');
}

@font-face {
    font-family: 'Read-Regular';
    font-display: auto;
    src: url('./assets/fonts/Read-Regular_V1100.ttf') format('truetype');
}

@font-face {
    font-family: 'Read-Medium';
    font-display: auto;
    src: url('./assets/fonts/Read-Medium_V1100.ttf') format('truetype');
}

@font-face {
    font-family: 'Read-Bold';
    font-display: auto;
    src: url('./assets/fonts/Read-Bold_V1100.ttf') format('truetype');
}

@font-face {
    font-family: 'Read-Italic';
    font-display: auto;
    src: url('./assets/fonts/Read-Italic_V1100.ttf') format('truetype');
} */

body {
    background-color: #f3f3f6 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.modal-footer {
    border-top: 0 !important;
}

.modal-title,
p,
h5,
h4,
h3,
h2,
h1 {

    font-family: -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        'Open Sans',
        'Helvetica Neue',
        sans-serif !important;
    margin-bottom: 0 !important;
}

button:focus,
.wrapper:focus {
    outline: none !important;
}

table td .link-icon:hover,
td div[id*="model-"]:hover {
    background: #eee;
    color: #000;
    display: inline-block;
    border-radius: 50%;
}

.card {
    box-shadow: 0 2px 18px 1px rgba(49, 53, 72, .1) !important;
    border-radius: 0 !important;
}

.card-body {
    padding: 2rem !important;
}

input.form-control.input-lg,
.btn-lg {
    border-radius: 0 !important;
    font-size: 1rem !important;
}

.is-other-month{
 display: none !important;
}