.animated {
  -webkit-animation-duration : 1s;
     -moz-animation-duration : 1s;
       -o-animation-duration : 1s;
          animation-duration : 1s;
  -webkit-animation-fill-mode : both;
     -moz-animation-fill-mode : both;
       -o-animation-fill-mode : both;
          animation-fill-mode : both;
}
.animated.infinite {
  -webkit-animation-iteration-count : infinite;
     -moz-animation-iteration-count : infinite;
       -o-animation-iteration-count : infinite;
          animation-iteration-count : infinite;
}
.animated.hinge {
  -webkit-animation-duration : 2s;
     -moz-animation-duration : 2s;
       -o-animation-duration : 2s;
          animation-duration : 2s;
}
.animated.flipOutX, .animated.flipOutY, .animated.bounceIn, .animated.bounceOut {
  -webkit-animation-duration : 0.75s;
     -moz-animation-duration : 0.75s;
       -o-animation-duration : 0.75s;
          animation-duration : 0.75s;
}

@-webkit-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform : translate3d(0, -30px, 0);
            transform : translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform : translate3d(0, -15px, 0);
            transform : translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform : translate3d(0, -4px, 0);
            transform : translate3d(0, -4px, 0);
  }
}

@-moz-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
       -moz-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform : translate3d(0, -30px, 0);
       -moz-transform : translate3d(0, -30px, 0);
            transform : translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
       -moz-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform : translate3d(0, -15px, 0);
       -moz-transform : translate3d(0, -15px, 0);
            transform : translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform : translate3d(0, -4px, 0);
       -moz-transform : translate3d(0, -4px, 0);
            transform : translate3d(0, -4px, 0);
  }
}

@-o-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
         -o-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
         -o-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform : translate3d(0, -30px, 0);
            transform : translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
         -o-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform : translate3d(0, -15px, 0);
            transform : translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform : translate3d(0, -4px, 0);
            transform : translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
         -o-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  40%, 43% {
    -webkit-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
       -moz-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
         -o-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform : translate3d(0, -30px, 0);
       -moz-transform : translate3d(0, -30px, 0);
            transform : translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
       -moz-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
         -o-animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function : cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform : translate3d(0, -15px, 0);
       -moz-transform : translate3d(0, -15px, 0);
            transform : translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform : translate3d(0, -4px, 0);
       -moz-transform : translate3d(0, -4px, 0);
            transform : translate3d(0, -4px, 0);
  }
}

.bounce {
  -webkit-animation-name : bounce;
     -moz-animation-name : bounce;
       -o-animation-name : bounce;
          animation-name : bounce;
  -webkit-transform-origin : center bottom;
     -moz-transform-origin : center bottom;
      -ms-transform-origin : center bottom;
       -o-transform-origin : center bottom;
          transform-origin : center bottom;
}

@-webkit-keyframes flash {
  from, 50%, to {
    opacity : 1;
  }
  25%, 75% {
    opacity : 0;
  }
}

@-moz-keyframes flash {
  from, 50%, to {
    opacity : 1;
  }
  25%, 75% {
    opacity : 0;
  }
}

@-o-keyframes flash {
  from, 50%, to {
    opacity : 1;
  }
  25%, 75% {
    opacity : 0;
  }
}

@keyframes flash {
  from, 50%, to {
    opacity : 1;
  }
  25%, 75% {
    opacity : 0;
  }
}

.flash {
  -webkit-animation-name : flash;
     -moz-animation-name : flash;
       -o-animation-name : flash;
          animation-name : flash;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes pulse {
  from {
    -webkit-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform : scale3d(1.05, 1.05, 1.05);
            transform : scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

@-moz-keyframes pulse {
  from {
    -webkit-transform : scale3d(1, 1, 1);
       -moz-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform : scale3d(1.05, 1.05, 1.05);
       -moz-transform : scale3d(1.05, 1.05, 1.05);
            transform : scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform : scale3d(1, 1, 1);
       -moz-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

@-o-keyframes pulse {
  from {
    -webkit-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform : scale3d(1.05, 1.05, 1.05);
            transform : scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform : scale3d(1, 1, 1);
       -moz-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform : scale3d(1.05, 1.05, 1.05);
       -moz-transform : scale3d(1.05, 1.05, 1.05);
            transform : scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform : scale3d(1, 1, 1);
       -moz-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation-name : pulse;
     -moz-animation-name : pulse;
       -o-animation-name : pulse;
          animation-name : pulse;
}

@-webkit-keyframes rubberBand {
  from {
    -webkit-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform : scale3d(1.25, 0.75, 1);
            transform : scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform : scale3d(0.75, 1.25, 1);
            transform : scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform : scale3d(1.15, 0.85, 1);
            transform : scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform : scale3d(0.95, 1.05, 1);
            transform : scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform : scale3d(1.05, 0.95, 1);
            transform : scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

@-moz-keyframes rubberBand {
  from {
    -webkit-transform : scale3d(1, 1, 1);
       -moz-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform : scale3d(1.25, 0.75, 1);
       -moz-transform : scale3d(1.25, 0.75, 1);
            transform : scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform : scale3d(0.75, 1.25, 1);
       -moz-transform : scale3d(0.75, 1.25, 1);
            transform : scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform : scale3d(1.15, 0.85, 1);
       -moz-transform : scale3d(1.15, 0.85, 1);
            transform : scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform : scale3d(0.95, 1.05, 1);
       -moz-transform : scale3d(0.95, 1.05, 1);
            transform : scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform : scale3d(1.05, 0.95, 1);
       -moz-transform : scale3d(1.05, 0.95, 1);
            transform : scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform : scale3d(1, 1, 1);
       -moz-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

@-o-keyframes rubberBand {
  from {
    -webkit-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform : scale3d(1.25, 0.75, 1);
            transform : scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform : scale3d(0.75, 1.25, 1);
            transform : scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform : scale3d(1.15, 0.85, 1);
            transform : scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform : scale3d(0.95, 1.05, 1);
            transform : scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform : scale3d(1.05, 0.95, 1);
            transform : scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  from {
    -webkit-transform : scale3d(1, 1, 1);
       -moz-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform : scale3d(1.25, 0.75, 1);
       -moz-transform : scale3d(1.25, 0.75, 1);
            transform : scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform : scale3d(0.75, 1.25, 1);
       -moz-transform : scale3d(0.75, 1.25, 1);
            transform : scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform : scale3d(1.15, 0.85, 1);
       -moz-transform : scale3d(1.15, 0.85, 1);
            transform : scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform : scale3d(0.95, 1.05, 1);
       -moz-transform : scale3d(0.95, 1.05, 1);
            transform : scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform : scale3d(1.05, 0.95, 1);
       -moz-transform : scale3d(1.05, 0.95, 1);
            transform : scale3d(1.05, 0.95, 1);
  }
  to {
    -webkit-transform : scale3d(1, 1, 1);
       -moz-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

.rubberBand {
  -webkit-animation-name : rubberBand;
     -moz-animation-name : rubberBand;
       -o-animation-name : rubberBand;
          animation-name : rubberBand;
}

@-webkit-keyframes shake {
  from, to {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform : translate3d(-10px, 0, 0);
            transform : translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform : translate3d(10px, 0, 0);
            transform : translate3d(10px, 0, 0);
  }
}

@-moz-keyframes shake {
  from, to {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform : translate3d(-10px, 0, 0);
       -moz-transform : translate3d(-10px, 0, 0);
            transform : translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform : translate3d(10px, 0, 0);
       -moz-transform : translate3d(10px, 0, 0);
            transform : translate3d(10px, 0, 0);
  }
}

@-o-keyframes shake {
  from, to {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform : translate3d(-10px, 0, 0);
            transform : translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform : translate3d(10px, 0, 0);
            transform : translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  from, to {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform : translate3d(-10px, 0, 0);
       -moz-transform : translate3d(-10px, 0, 0);
            transform : translate3d(-10px, 0, 0);
  }
  20%, 40%, 60%, 80% {
    -webkit-transform : translate3d(10px, 0, 0);
       -moz-transform : translate3d(10px, 0, 0);
            transform : translate3d(10px, 0, 0);
  }
}

.shake {
  -webkit-animation-name : shake;
     -moz-animation-name : shake;
       -o-animation-name : shake;
          animation-name : shake;
}

@-webkit-keyframes headShake {
  0% {
    -webkit-transform : translateX(0);
            transform : translateX(0);
  }
  6.5% {
    -webkit-transform : translateX(-6px) rotateY(-9deg);
            transform : translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform : translateX(5px) rotateY(7deg);
            transform : translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform : translateX(-3px) rotateY(-5deg);
            transform : translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform : translateX(2px) rotateY(3deg);
            transform : translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform : translateX(0);
            transform : translateX(0);
  }
}

@-moz-keyframes headShake {
  0% {
    -webkit-transform : translateX(0);
       -moz-transform : translateX(0);
            transform : translateX(0);
  }
  6.5% {
    -webkit-transform : translateX(-6px) rotateY(-9deg);
       -moz-transform : translateX(-6px) rotateY(-9deg);
            transform : translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform : translateX(5px) rotateY(7deg);
       -moz-transform : translateX(5px) rotateY(7deg);
            transform : translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform : translateX(-3px) rotateY(-5deg);
       -moz-transform : translateX(-3px) rotateY(-5deg);
            transform : translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform : translateX(2px) rotateY(3deg);
       -moz-transform : translateX(2px) rotateY(3deg);
            transform : translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform : translateX(0);
       -moz-transform : translateX(0);
            transform : translateX(0);
  }
}

@-o-keyframes headShake {
  0% {
    -webkit-transform : translateX(0);
         -o-transform : translateX(0);
            transform : translateX(0);
  }
  6.5% {
    -webkit-transform : translateX(-6px) rotateY(-9deg);
            transform : translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform : translateX(5px) rotateY(7deg);
            transform : translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform : translateX(-3px) rotateY(-5deg);
            transform : translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform : translateX(2px) rotateY(3deg);
            transform : translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform : translateX(0);
         -o-transform : translateX(0);
            transform : translateX(0);
  }
}

@keyframes headShake {
  0% {
    -webkit-transform : translateX(0);
       -moz-transform : translateX(0);
         -o-transform : translateX(0);
            transform : translateX(0);
  }
  6.5% {
    -webkit-transform : translateX(-6px) rotateY(-9deg);
       -moz-transform : translateX(-6px) rotateY(-9deg);
            transform : translateX(-6px) rotateY(-9deg);
  }
  18.5% {
    -webkit-transform : translateX(5px) rotateY(7deg);
       -moz-transform : translateX(5px) rotateY(7deg);
            transform : translateX(5px) rotateY(7deg);
  }
  31.5% {
    -webkit-transform : translateX(-3px) rotateY(-5deg);
       -moz-transform : translateX(-3px) rotateY(-5deg);
            transform : translateX(-3px) rotateY(-5deg);
  }
  43.5% {
    -webkit-transform : translateX(2px) rotateY(3deg);
       -moz-transform : translateX(2px) rotateY(3deg);
            transform : translateX(2px) rotateY(3deg);
  }
  50% {
    -webkit-transform : translateX(0);
       -moz-transform : translateX(0);
         -o-transform : translateX(0);
            transform : translateX(0);
  }
}

.headShake {
  -webkit-animation-timing-function : ease-in-out;
     -moz-animation-timing-function : ease-in-out;
       -o-animation-timing-function : ease-in-out;
          animation-timing-function : ease-in-out;
  -webkit-animation-name : headShake;
     -moz-animation-name : headShake;
       -o-animation-name : headShake;
          animation-name : headShake;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform : rotate3d(0, 0, 1, 15deg);
            transform : rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform : rotate3d(0, 0, 1, -10deg);
            transform : rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform : rotate3d(0, 0, 1, 5deg);
            transform : rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform : rotate3d(0, 0, 1, -5deg);
            transform : rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform : rotate3d(0, 0, 1, 0deg);
            transform : rotate3d(0, 0, 1, 0deg);
  }
}

@-moz-keyframes swing {
  20% {
    -webkit-transform : rotate3d(0, 0, 1, 15deg);
       -moz-transform : rotate3d(0, 0, 1, 15deg);
            transform : rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform : rotate3d(0, 0, 1, -10deg);
       -moz-transform : rotate3d(0, 0, 1, -10deg);
            transform : rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform : rotate3d(0, 0, 1, 5deg);
       -moz-transform : rotate3d(0, 0, 1, 5deg);
            transform : rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform : rotate3d(0, 0, 1, -5deg);
       -moz-transform : rotate3d(0, 0, 1, -5deg);
            transform : rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform : rotate3d(0, 0, 1, 0deg);
       -moz-transform : rotate3d(0, 0, 1, 0deg);
            transform : rotate3d(0, 0, 1, 0deg);
  }
}

@-o-keyframes swing {
  20% {
    -webkit-transform : rotate3d(0, 0, 1, 15deg);
            transform : rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform : rotate3d(0, 0, 1, -10deg);
            transform : rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform : rotate3d(0, 0, 1, 5deg);
            transform : rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform : rotate3d(0, 0, 1, -5deg);
            transform : rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform : rotate3d(0, 0, 1, 0deg);
            transform : rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes swing {
  20% {
    -webkit-transform : rotate3d(0, 0, 1, 15deg);
       -moz-transform : rotate3d(0, 0, 1, 15deg);
            transform : rotate3d(0, 0, 1, 15deg);
  }
  40% {
    -webkit-transform : rotate3d(0, 0, 1, -10deg);
       -moz-transform : rotate3d(0, 0, 1, -10deg);
            transform : rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform : rotate3d(0, 0, 1, 5deg);
       -moz-transform : rotate3d(0, 0, 1, 5deg);
            transform : rotate3d(0, 0, 1, 5deg);
  }
  80% {
    -webkit-transform : rotate3d(0, 0, 1, -5deg);
       -moz-transform : rotate3d(0, 0, 1, -5deg);
            transform : rotate3d(0, 0, 1, -5deg);
  }
  to {
    -webkit-transform : rotate3d(0, 0, 1, 0deg);
       -moz-transform : rotate3d(0, 0, 1, 0deg);
            transform : rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  -webkit-transform-origin : top center;
     -moz-transform-origin : top center;
      -ms-transform-origin : top center;
       -o-transform-origin : top center;
          transform-origin : top center;
  -webkit-animation-name : swing;
     -moz-animation-name : swing;
       -o-animation-name : swing;
          animation-name : swing;
}

@-webkit-keyframes tada {
  from {
    -webkit-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform : scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
            transform : scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
            transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
            transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

@-moz-keyframes tada {
  from {
    -webkit-transform : scale3d(1, 1, 1);
       -moz-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform : scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
       -moz-transform : scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
            transform : scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
       -moz-transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
            transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
       -moz-transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
            transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform : scale3d(1, 1, 1);
       -moz-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

@-o-keyframes tada {
  from {
    -webkit-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform : scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
            transform : scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
            transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
            transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform : scale3d(1, 1, 1);
       -moz-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
  10%, 20% {
    -webkit-transform : scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
       -moz-transform : scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
            transform : scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
       -moz-transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
            transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    -webkit-transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
       -moz-transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
            transform : scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    -webkit-transform : scale3d(1, 1, 1);
       -moz-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

.tada {
  -webkit-animation-name : tada;
     -moz-animation-name : tada;
       -o-animation-name : tada;
          animation-name : tada;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes wobble {
  from {
    -webkit-transform : none;
            transform : none;
  }
  15% {
    -webkit-transform : translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
            transform : translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform : translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
            transform : translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform : translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
            transform : translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform : translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
            transform : translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform : translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
            transform : translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform : none;
            transform : none;
  }
}

@-moz-keyframes wobble {
  from {
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
  15% {
    -webkit-transform : translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
       -moz-transform : translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
            transform : translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform : translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
       -moz-transform : translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
            transform : translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform : translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
       -moz-transform : translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
            transform : translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform : translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
       -moz-transform : translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
            transform : translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform : translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
       -moz-transform : translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
            transform : translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
}

@-o-keyframes wobble {
  from {
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
  15% {
    -webkit-transform : translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
            transform : translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform : translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
            transform : translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform : translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
            transform : translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform : translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
            transform : translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform : translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
            transform : translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
}

@keyframes wobble {
  from {
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
  15% {
    -webkit-transform : translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
       -moz-transform : translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
            transform : translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform : translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
       -moz-transform : translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
            transform : translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform : translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
       -moz-transform : translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
            transform : translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform : translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
       -moz-transform : translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
            transform : translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform : translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
       -moz-transform : translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
            transform : translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  to {
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
}

.wobble {
  -webkit-animation-name : wobble;
     -moz-animation-name : wobble;
       -o-animation-name : wobble;
          animation-name : wobble;
}

@-webkit-keyframes jello {
  from, 11.1%, to {
    -webkit-transform : none;
            transform : none;
  }
  22.2% {
    -webkit-transform : skewX(-12.5deg) skewY(-12.5deg);
            transform : skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform : skewX(6.25deg) skewY(6.25deg);
            transform : skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform : skewX(-3.125deg) skewY(-3.125deg);
            transform : skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform : skewX(1.5625deg) skewY(1.5625deg);
            transform : skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform : skewX(-0.78125deg) skewY(-0.78125deg);
            transform : skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform : skewX(0.39063deg) skewY(0.39063deg);
            transform : skewX(0.39063deg) skewY(0.39063deg);
  }
  88.8% {
    -webkit-transform : skewX(-0.19531deg) skewY(-0.19531deg);
            transform : skewX(-0.19531deg) skewY(-0.19531deg);
  }
}

@-moz-keyframes jello {
  from, 11.1%, to {
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
  22.2% {
    -webkit-transform : skewX(-12.5deg) skewY(-12.5deg);
       -moz-transform : skewX(-12.5deg) skewY(-12.5deg);
            transform : skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform : skewX(6.25deg) skewY(6.25deg);
       -moz-transform : skewX(6.25deg) skewY(6.25deg);
            transform : skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform : skewX(-3.125deg) skewY(-3.125deg);
       -moz-transform : skewX(-3.125deg) skewY(-3.125deg);
            transform : skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform : skewX(1.5625deg) skewY(1.5625deg);
       -moz-transform : skewX(1.5625deg) skewY(1.5625deg);
            transform : skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform : skewX(-0.78125deg) skewY(-0.78125deg);
       -moz-transform : skewX(-0.78125deg) skewY(-0.78125deg);
            transform : skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform : skewX(0.39063deg) skewY(0.39063deg);
       -moz-transform : skewX(0.39063deg) skewY(0.39063deg);
            transform : skewX(0.39063deg) skewY(0.39063deg);
  }
  88.8% {
    -webkit-transform : skewX(-0.19531deg) skewY(-0.19531deg);
       -moz-transform : skewX(-0.19531deg) skewY(-0.19531deg);
            transform : skewX(-0.19531deg) skewY(-0.19531deg);
  }
}

@-o-keyframes jello {
  from, 11.1%, to {
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
  22.2% {
    -webkit-transform : skewX(-12.5deg) skewY(-12.5deg);
         -o-transform : skewX(-12.5deg) skewY(-12.5deg);
            transform : skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform : skewX(6.25deg) skewY(6.25deg);
         -o-transform : skewX(6.25deg) skewY(6.25deg);
            transform : skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform : skewX(-3.125deg) skewY(-3.125deg);
         -o-transform : skewX(-3.125deg) skewY(-3.125deg);
            transform : skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform : skewX(1.5625deg) skewY(1.5625deg);
         -o-transform : skewX(1.5625deg) skewY(1.5625deg);
            transform : skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform : skewX(-0.78125deg) skewY(-0.78125deg);
         -o-transform : skewX(-0.78125deg) skewY(-0.78125deg);
            transform : skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform : skewX(0.39063deg) skewY(0.39063deg);
         -o-transform : skewX(0.39063deg) skewY(0.39063deg);
            transform : skewX(0.39063deg) skewY(0.39063deg);
  }
  88.8% {
    -webkit-transform : skewX(-0.19531deg) skewY(-0.19531deg);
         -o-transform : skewX(-0.19531deg) skewY(-0.19531deg);
            transform : skewX(-0.19531deg) skewY(-0.19531deg);
  }
}

@keyframes jello {
  from, 11.1%, to {
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
  22.2% {
    -webkit-transform : skewX(-12.5deg) skewY(-12.5deg);
       -moz-transform : skewX(-12.5deg) skewY(-12.5deg);
         -o-transform : skewX(-12.5deg) skewY(-12.5deg);
            transform : skewX(-12.5deg) skewY(-12.5deg);
  }
  33.3% {
    -webkit-transform : skewX(6.25deg) skewY(6.25deg);
       -moz-transform : skewX(6.25deg) skewY(6.25deg);
         -o-transform : skewX(6.25deg) skewY(6.25deg);
            transform : skewX(6.25deg) skewY(6.25deg);
  }
  44.4% {
    -webkit-transform : skewX(-3.125deg) skewY(-3.125deg);
       -moz-transform : skewX(-3.125deg) skewY(-3.125deg);
         -o-transform : skewX(-3.125deg) skewY(-3.125deg);
            transform : skewX(-3.125deg) skewY(-3.125deg);
  }
  55.5% {
    -webkit-transform : skewX(1.5625deg) skewY(1.5625deg);
       -moz-transform : skewX(1.5625deg) skewY(1.5625deg);
         -o-transform : skewX(1.5625deg) skewY(1.5625deg);
            transform : skewX(1.5625deg) skewY(1.5625deg);
  }
  66.6% {
    -webkit-transform : skewX(-0.78125deg) skewY(-0.78125deg);
       -moz-transform : skewX(-0.78125deg) skewY(-0.78125deg);
         -o-transform : skewX(-0.78125deg) skewY(-0.78125deg);
            transform : skewX(-0.78125deg) skewY(-0.78125deg);
  }
  77.7% {
    -webkit-transform : skewX(0.39063deg) skewY(0.39063deg);
       -moz-transform : skewX(0.39063deg) skewY(0.39063deg);
         -o-transform : skewX(0.39063deg) skewY(0.39063deg);
            transform : skewX(0.39063deg) skewY(0.39063deg);
  }
  88.8% {
    -webkit-transform : skewX(-0.19531deg) skewY(-0.19531deg);
       -moz-transform : skewX(-0.19531deg) skewY(-0.19531deg);
         -o-transform : skewX(-0.19531deg) skewY(-0.19531deg);
            transform : skewX(-0.19531deg) skewY(-0.19531deg);
  }
}

.jello {
  -webkit-animation-name : jello;
     -moz-animation-name : jello;
       -o-animation-name : jello;
          animation-name : jello;
  -webkit-transform-origin : center;
     -moz-transform-origin : center;
      -ms-transform-origin : center;
       -o-transform-origin : center;
          transform-origin : center;
}

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform : scale3d(1.1, 1.1, 1.1);
            transform : scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform : scale3d(0.9, 0.9, 0.9);
            transform : scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(1.03, 1.03, 1.03);
            transform : scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform : scale3d(0.97, 0.97, 0.97);
            transform : scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity : 1;
    -webkit-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

@-moz-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
       -moz-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform : scale3d(1.1, 1.1, 1.1);
       -moz-transform : scale3d(1.1, 1.1, 1.1);
            transform : scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform : scale3d(0.9, 0.9, 0.9);
       -moz-transform : scale3d(0.9, 0.9, 0.9);
            transform : scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(1.03, 1.03, 1.03);
       -moz-transform : scale3d(1.03, 1.03, 1.03);
            transform : scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform : scale3d(0.97, 0.97, 0.97);
       -moz-transform : scale3d(0.97, 0.97, 0.97);
            transform : scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity : 1;
    -webkit-transform : scale3d(1, 1, 1);
       -moz-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

@-o-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
         -o-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform : scale3d(1.1, 1.1, 1.1);
            transform : scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform : scale3d(0.9, 0.9, 0.9);
            transform : scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(1.03, 1.03, 1.03);
            transform : scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform : scale3d(0.97, 0.97, 0.97);
            transform : scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity : 1;
    -webkit-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
         -o-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
       -moz-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform : scale3d(1.1, 1.1, 1.1);
       -moz-transform : scale3d(1.1, 1.1, 1.1);
            transform : scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform : scale3d(0.9, 0.9, 0.9);
       -moz-transform : scale3d(0.9, 0.9, 0.9);
            transform : scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(1.03, 1.03, 1.03);
       -moz-transform : scale3d(1.03, 1.03, 1.03);
            transform : scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform : scale3d(0.97, 0.97, 0.97);
       -moz-transform : scale3d(0.97, 0.97, 0.97);
            transform : scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity : 1;
    -webkit-transform : scale3d(1, 1, 1);
       -moz-transform : scale3d(1, 1, 1);
            transform : scale3d(1, 1, 1);
  }
}

.bounceIn {
  -webkit-animation-name : bounceIn;
     -moz-animation-name : bounceIn;
       -o-animation-name : bounceIn;
          animation-name : bounceIn;
}

@-webkit-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity : 0;
    -webkit-transform : translate3d(0, -3000px, 0);
            transform : translate3d(0, -3000px, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(0, 25px, 0);
            transform : translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform : translate3d(0, -10px, 0);
            transform : translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform : translate3d(0, 5px, 0);
            transform : translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform : none;
            transform : none;
  }
}

@-moz-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity : 0;
    -webkit-transform : translate3d(0, -3000px, 0);
       -moz-transform : translate3d(0, -3000px, 0);
            transform : translate3d(0, -3000px, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(0, 25px, 0);
       -moz-transform : translate3d(0, 25px, 0);
            transform : translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform : translate3d(0, -10px, 0);
       -moz-transform : translate3d(0, -10px, 0);
            transform : translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform : translate3d(0, 5px, 0);
       -moz-transform : translate3d(0, 5px, 0);
            transform : translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
}

@-o-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
         -o-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity : 0;
    -webkit-transform : translate3d(0, -3000px, 0);
            transform : translate3d(0, -3000px, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(0, 25px, 0);
            transform : translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform : translate3d(0, -10px, 0);
            transform : translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform : translate3d(0, 5px, 0);
            transform : translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
         -o-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity : 0;
    -webkit-transform : translate3d(0, -3000px, 0);
       -moz-transform : translate3d(0, -3000px, 0);
            transform : translate3d(0, -3000px, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(0, 25px, 0);
       -moz-transform : translate3d(0, 25px, 0);
            transform : translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform : translate3d(0, -10px, 0);
       -moz-transform : translate3d(0, -10px, 0);
            transform : translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform : translate3d(0, 5px, 0);
       -moz-transform : translate3d(0, 5px, 0);
            transform : translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
}

.bounceInDown {
  -webkit-animation-name : bounceInDown;
     -moz-animation-name : bounceInDown;
       -o-animation-name : bounceInDown;
          animation-name : bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity : 0;
    -webkit-transform : translate3d(-3000px, 0, 0);
            transform : translate3d(-3000px, 0, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(25px, 0, 0);
            transform : translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform : translate3d(-10px, 0, 0);
            transform : translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform : translate3d(5px, 0, 0);
            transform : translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform : none;
            transform : none;
  }
}

@-moz-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity : 0;
    -webkit-transform : translate3d(-3000px, 0, 0);
       -moz-transform : translate3d(-3000px, 0, 0);
            transform : translate3d(-3000px, 0, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(25px, 0, 0);
       -moz-transform : translate3d(25px, 0, 0);
            transform : translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform : translate3d(-10px, 0, 0);
       -moz-transform : translate3d(-10px, 0, 0);
            transform : translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform : translate3d(5px, 0, 0);
       -moz-transform : translate3d(5px, 0, 0);
            transform : translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
}

@-o-keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
         -o-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity : 0;
    -webkit-transform : translate3d(-3000px, 0, 0);
            transform : translate3d(-3000px, 0, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(25px, 0, 0);
            transform : translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform : translate3d(-10px, 0, 0);
            transform : translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform : translate3d(5px, 0, 0);
            transform : translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
         -o-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity : 0;
    -webkit-transform : translate3d(-3000px, 0, 0);
       -moz-transform : translate3d(-3000px, 0, 0);
            transform : translate3d(-3000px, 0, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(25px, 0, 0);
       -moz-transform : translate3d(25px, 0, 0);
            transform : translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform : translate3d(-10px, 0, 0);
       -moz-transform : translate3d(-10px, 0, 0);
            transform : translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform : translate3d(5px, 0, 0);
       -moz-transform : translate3d(5px, 0, 0);
            transform : translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
}

.bounceInLeft {
  -webkit-animation-name : bounceInLeft;
     -moz-animation-name : bounceInLeft;
       -o-animation-name : bounceInLeft;
          animation-name : bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity : 0;
    -webkit-transform : translate3d(3000px, 0, 0);
            transform : translate3d(3000px, 0, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(-25px, 0, 0);
            transform : translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform : translate3d(10px, 0, 0);
            transform : translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform : translate3d(-5px, 0, 0);
            transform : translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform : none;
            transform : none;
  }
}

@-moz-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity : 0;
    -webkit-transform : translate3d(3000px, 0, 0);
       -moz-transform : translate3d(3000px, 0, 0);
            transform : translate3d(3000px, 0, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(-25px, 0, 0);
       -moz-transform : translate3d(-25px, 0, 0);
            transform : translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform : translate3d(10px, 0, 0);
       -moz-transform : translate3d(10px, 0, 0);
            transform : translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform : translate3d(-5px, 0, 0);
       -moz-transform : translate3d(-5px, 0, 0);
            transform : translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
}

@-o-keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
         -o-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity : 0;
    -webkit-transform : translate3d(3000px, 0, 0);
            transform : translate3d(3000px, 0, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(-25px, 0, 0);
            transform : translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform : translate3d(10px, 0, 0);
            transform : translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform : translate3d(-5px, 0, 0);
            transform : translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
         -o-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity : 0;
    -webkit-transform : translate3d(3000px, 0, 0);
       -moz-transform : translate3d(3000px, 0, 0);
            transform : translate3d(3000px, 0, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(-25px, 0, 0);
       -moz-transform : translate3d(-25px, 0, 0);
            transform : translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform : translate3d(10px, 0, 0);
       -moz-transform : translate3d(10px, 0, 0);
            transform : translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform : translate3d(-5px, 0, 0);
       -moz-transform : translate3d(-5px, 0, 0);
            transform : translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
}

.bounceInRight {
  -webkit-animation-name : bounceInRight;
     -moz-animation-name : bounceInRight;
       -o-animation-name : bounceInRight;
          animation-name : bounceInRight;
}

@-webkit-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, 3000px, 0);
            transform : translate3d(0, 3000px, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(0, -20px, 0);
            transform : translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform : translate3d(0, 10px, 0);
            transform : translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform : translate3d(0, -5px, 0);
            transform : translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@-moz-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, 3000px, 0);
       -moz-transform : translate3d(0, 3000px, 0);
            transform : translate3d(0, 3000px, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(0, -20px, 0);
       -moz-transform : translate3d(0, -20px, 0);
            transform : translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform : translate3d(0, 10px, 0);
       -moz-transform : translate3d(0, 10px, 0);
            transform : translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform : translate3d(0, -5px, 0);
       -moz-transform : translate3d(0, -5px, 0);
            transform : translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@-o-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
         -o-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, 3000px, 0);
            transform : translate3d(0, 3000px, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(0, -20px, 0);
            transform : translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform : translate3d(0, 10px, 0);
            transform : translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform : translate3d(0, -5px, 0);
            transform : translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
       -moz-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
         -o-animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function : cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, 3000px, 0);
       -moz-transform : translate3d(0, 3000px, 0);
            transform : translate3d(0, 3000px, 0);
  }
  60% {
    opacity : 1;
    -webkit-transform : translate3d(0, -20px, 0);
       -moz-transform : translate3d(0, -20px, 0);
            transform : translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform : translate3d(0, 10px, 0);
       -moz-transform : translate3d(0, 10px, 0);
            transform : translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform : translate3d(0, -5px, 0);
       -moz-transform : translate3d(0, -5px, 0);
            transform : translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

.bounceInUp {
  -webkit-animation-name : bounceInUp;
     -moz-animation-name : bounceInUp;
       -o-animation-name : bounceInUp;
          animation-name : bounceInUp;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform : scale3d(0.9, 0.9, 0.9);
            transform : scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity : 1;
    -webkit-transform : scale3d(1.1, 1.1, 1.1);
            transform : scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
}

@-moz-keyframes bounceOut {
  20% {
    -webkit-transform : scale3d(0.9, 0.9, 0.9);
       -moz-transform : scale3d(0.9, 0.9, 0.9);
            transform : scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity : 1;
    -webkit-transform : scale3d(1.1, 1.1, 1.1);
       -moz-transform : scale3d(1.1, 1.1, 1.1);
            transform : scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
       -moz-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
}

@-o-keyframes bounceOut {
  20% {
    -webkit-transform : scale3d(0.9, 0.9, 0.9);
            transform : scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity : 1;
    -webkit-transform : scale3d(1.1, 1.1, 1.1);
            transform : scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform : scale3d(0.9, 0.9, 0.9);
       -moz-transform : scale3d(0.9, 0.9, 0.9);
            transform : scale3d(0.9, 0.9, 0.9);
  }
  50%, 55% {
    opacity : 1;
    -webkit-transform : scale3d(1.1, 1.1, 1.1);
       -moz-transform : scale3d(1.1, 1.1, 1.1);
            transform : scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
       -moz-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
}

.bounceOut {
  -webkit-animation-name : bounceOut;
     -moz-animation-name : bounceOut;
       -o-animation-name : bounceOut;
          animation-name : bounceOut;
}

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform : translate3d(0, 10px, 0);
            transform : translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity : 1;
    -webkit-transform : translate3d(0, -20px, 0);
            transform : translate3d(0, -20px, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, 2000px, 0);
            transform : translate3d(0, 2000px, 0);
  }
}

@-moz-keyframes bounceOutDown {
  20% {
    -webkit-transform : translate3d(0, 10px, 0);
       -moz-transform : translate3d(0, 10px, 0);
            transform : translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity : 1;
    -webkit-transform : translate3d(0, -20px, 0);
       -moz-transform : translate3d(0, -20px, 0);
            transform : translate3d(0, -20px, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, 2000px, 0);
       -moz-transform : translate3d(0, 2000px, 0);
            transform : translate3d(0, 2000px, 0);
  }
}

@-o-keyframes bounceOutDown {
  20% {
    -webkit-transform : translate3d(0, 10px, 0);
            transform : translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity : 1;
    -webkit-transform : translate3d(0, -20px, 0);
            transform : translate3d(0, -20px, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, 2000px, 0);
            transform : translate3d(0, 2000px, 0);
  }
}

@keyframes bounceOutDown {
  20% {
    -webkit-transform : translate3d(0, 10px, 0);
       -moz-transform : translate3d(0, 10px, 0);
            transform : translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity : 1;
    -webkit-transform : translate3d(0, -20px, 0);
       -moz-transform : translate3d(0, -20px, 0);
            transform : translate3d(0, -20px, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, 2000px, 0);
       -moz-transform : translate3d(0, 2000px, 0);
            transform : translate3d(0, 2000px, 0);
  }
}

.bounceOutDown {
  -webkit-animation-name : bounceOutDown;
     -moz-animation-name : bounceOutDown;
       -o-animation-name : bounceOutDown;
          animation-name : bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity : 1;
    -webkit-transform : translate3d(20px, 0, 0);
            transform : translate3d(20px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(-2000px, 0, 0);
            transform : translate3d(-2000px, 0, 0);
  }
}

@-moz-keyframes bounceOutLeft {
  20% {
    opacity : 1;
    -webkit-transform : translate3d(20px, 0, 0);
       -moz-transform : translate3d(20px, 0, 0);
            transform : translate3d(20px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(-2000px, 0, 0);
       -moz-transform : translate3d(-2000px, 0, 0);
            transform : translate3d(-2000px, 0, 0);
  }
}

@-o-keyframes bounceOutLeft {
  20% {
    opacity : 1;
    -webkit-transform : translate3d(20px, 0, 0);
            transform : translate3d(20px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(-2000px, 0, 0);
            transform : translate3d(-2000px, 0, 0);
  }
}

@keyframes bounceOutLeft {
  20% {
    opacity : 1;
    -webkit-transform : translate3d(20px, 0, 0);
       -moz-transform : translate3d(20px, 0, 0);
            transform : translate3d(20px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(-2000px, 0, 0);
       -moz-transform : translate3d(-2000px, 0, 0);
            transform : translate3d(-2000px, 0, 0);
  }
}

.bounceOutLeft {
  -webkit-animation-name : bounceOutLeft;
     -moz-animation-name : bounceOutLeft;
       -o-animation-name : bounceOutLeft;
          animation-name : bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  20% {
    opacity : 1;
    -webkit-transform : translate3d(-20px, 0, 0);
            transform : translate3d(-20px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(2000px, 0, 0);
            transform : translate3d(2000px, 0, 0);
  }
}

@-moz-keyframes bounceOutRight {
  20% {
    opacity : 1;
    -webkit-transform : translate3d(-20px, 0, 0);
       -moz-transform : translate3d(-20px, 0, 0);
            transform : translate3d(-20px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(2000px, 0, 0);
       -moz-transform : translate3d(2000px, 0, 0);
            transform : translate3d(2000px, 0, 0);
  }
}

@-o-keyframes bounceOutRight {
  20% {
    opacity : 1;
    -webkit-transform : translate3d(-20px, 0, 0);
            transform : translate3d(-20px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(2000px, 0, 0);
            transform : translate3d(2000px, 0, 0);
  }
}

@keyframes bounceOutRight {
  20% {
    opacity : 1;
    -webkit-transform : translate3d(-20px, 0, 0);
       -moz-transform : translate3d(-20px, 0, 0);
            transform : translate3d(-20px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(2000px, 0, 0);
       -moz-transform : translate3d(2000px, 0, 0);
            transform : translate3d(2000px, 0, 0);
  }
}

.bounceOutRight {
  -webkit-animation-name : bounceOutRight;
     -moz-animation-name : bounceOutRight;
       -o-animation-name : bounceOutRight;
          animation-name : bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform : translate3d(0, -10px, 0);
            transform : translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity : 1;
    -webkit-transform : translate3d(0, 20px, 0);
            transform : translate3d(0, 20px, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, -2000px, 0);
            transform : translate3d(0, -2000px, 0);
  }
}

@-moz-keyframes bounceOutUp {
  20% {
    -webkit-transform : translate3d(0, -10px, 0);
       -moz-transform : translate3d(0, -10px, 0);
            transform : translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity : 1;
    -webkit-transform : translate3d(0, 20px, 0);
       -moz-transform : translate3d(0, 20px, 0);
            transform : translate3d(0, 20px, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, -2000px, 0);
       -moz-transform : translate3d(0, -2000px, 0);
            transform : translate3d(0, -2000px, 0);
  }
}

@-o-keyframes bounceOutUp {
  20% {
    -webkit-transform : translate3d(0, -10px, 0);
            transform : translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity : 1;
    -webkit-transform : translate3d(0, 20px, 0);
            transform : translate3d(0, 20px, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, -2000px, 0);
            transform : translate3d(0, -2000px, 0);
  }
}

@keyframes bounceOutUp {
  20% {
    -webkit-transform : translate3d(0, -10px, 0);
       -moz-transform : translate3d(0, -10px, 0);
            transform : translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity : 1;
    -webkit-transform : translate3d(0, 20px, 0);
       -moz-transform : translate3d(0, 20px, 0);
            transform : translate3d(0, 20px, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, -2000px, 0);
       -moz-transform : translate3d(0, -2000px, 0);
            transform : translate3d(0, -2000px, 0);
  }
}

.bounceOutUp {
  -webkit-animation-name : bounceOutUp;
     -moz-animation-name : bounceOutUp;
       -o-animation-name : bounceOutUp;
          animation-name : bounceOutUp;
}

@-webkit-keyframes fadeIn {
  from {
    opacity : 0;
  }
  to {
    opacity : 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity : 0;
  }
  to {
    opacity : 1;
  }
}

@-o-keyframes fadeIn {
  from {
    opacity : 0;
  }
  to {
    opacity : 1;
  }
}

@keyframes fadeIn {
  from {
    opacity : 0;
  }
  to {
    opacity : 1;
  }
}

.fadeIn {
  -webkit-animation-name : fadeIn;
     -moz-animation-name : fadeIn;
       -o-animation-name : fadeIn;
          animation-name : fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
            transform : none;
  }
}

@-moz-keyframes fadeInDown {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, -100%, 0);
       -moz-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
}

@-o-keyframes fadeInDown {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
}

@keyframes fadeInDown {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, -100%, 0);
       -moz-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
}

.fadeInDown {
  -webkit-animation-name : fadeInDown;
     -moz-animation-name : fadeInDown;
       -o-animation-name : fadeInDown;
          animation-name : fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, -2000px, 0);
            transform : translate3d(0, -2000px, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
            transform : none;
  }
}

@-moz-keyframes fadeInDownBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, -2000px, 0);
       -moz-transform : translate3d(0, -2000px, 0);
            transform : translate3d(0, -2000px, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
}

@-o-keyframes fadeInDownBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, -2000px, 0);
            transform : translate3d(0, -2000px, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
}

@keyframes fadeInDownBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, -2000px, 0);
       -moz-transform : translate3d(0, -2000px, 0);
            transform : translate3d(0, -2000px, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
}

.fadeInDownBig {
  -webkit-animation-name : fadeInDownBig;
     -moz-animation-name : fadeInDownBig;
       -o-animation-name : fadeInDownBig;
          animation-name : fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity : 0;
    -webkit-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
            transform : none;
  }
}

@-moz-keyframes fadeInLeft {
  from {
    opacity : 0;
    -webkit-transform : translate3d(-100%, 0, 0);
       -moz-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
}

@-o-keyframes fadeInLeft {
  from {
    opacity : 0;
    -webkit-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity : 0;
    -webkit-transform : translate3d(-100%, 0, 0);
       -moz-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
}

.fadeInLeft {
  -webkit-animation-name : fadeInLeft;
     -moz-animation-name : fadeInLeft;
       -o-animation-name : fadeInLeft;
          animation-name : fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(-2000px, 0, 0);
            transform : translate3d(-2000px, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
            transform : none;
  }
}

@-moz-keyframes fadeInLeftBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(-2000px, 0, 0);
       -moz-transform : translate3d(-2000px, 0, 0);
            transform : translate3d(-2000px, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
}

@-o-keyframes fadeInLeftBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(-2000px, 0, 0);
            transform : translate3d(-2000px, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
}

@keyframes fadeInLeftBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(-2000px, 0, 0);
       -moz-transform : translate3d(-2000px, 0, 0);
            transform : translate3d(-2000px, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
}

.fadeInLeftBig {
  -webkit-animation-name : fadeInLeftBig;
     -moz-animation-name : fadeInLeftBig;
       -o-animation-name : fadeInLeftBig;
          animation-name : fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity : 0;
    -webkit-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
            transform : none;
  }
}

@-moz-keyframes fadeInRight {
  from {
    opacity : 0;
    -webkit-transform : translate3d(100%, 0, 0);
       -moz-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
}

@-o-keyframes fadeInRight {
  from {
    opacity : 0;
    -webkit-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
}

@keyframes fadeInRight {
  from {
    opacity : 0;
    -webkit-transform : translate3d(100%, 0, 0);
       -moz-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
}

.fadeInRight {
  -webkit-animation-name : fadeInRight;
     -moz-animation-name : fadeInRight;
       -o-animation-name : fadeInRight;
          animation-name : fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(2000px, 0, 0);
            transform : translate3d(2000px, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
            transform : none;
  }
}

@-moz-keyframes fadeInRightBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(2000px, 0, 0);
       -moz-transform : translate3d(2000px, 0, 0);
            transform : translate3d(2000px, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
}

@-o-keyframes fadeInRightBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(2000px, 0, 0);
            transform : translate3d(2000px, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
}

@keyframes fadeInRightBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(2000px, 0, 0);
       -moz-transform : translate3d(2000px, 0, 0);
            transform : translate3d(2000px, 0, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
}

.fadeInRightBig {
  -webkit-animation-name : fadeInRightBig;
     -moz-animation-name : fadeInRightBig;
       -o-animation-name : fadeInRightBig;
          animation-name : fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
            transform : none;
  }
}

@-moz-keyframes fadeInUp {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, 100%, 0);
       -moz-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
}

@-o-keyframes fadeInUp {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
}

@keyframes fadeInUp {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, 100%, 0);
       -moz-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
}

.fadeInUp {
  -webkit-animation-name : fadeInUp;
     -moz-animation-name : fadeInUp;
       -o-animation-name : fadeInUp;
          animation-name : fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, 2000px, 0);
            transform : translate3d(0, 2000px, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
            transform : none;
  }
}

@-moz-keyframes fadeInUpBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, 2000px, 0);
       -moz-transform : translate3d(0, 2000px, 0);
            transform : translate3d(0, 2000px, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
}

@-o-keyframes fadeInUpBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, 2000px, 0);
            transform : translate3d(0, 2000px, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
}

@keyframes fadeInUpBig {
  from {
    opacity : 0;
    -webkit-transform : translate3d(0, 2000px, 0);
       -moz-transform : translate3d(0, 2000px, 0);
            transform : translate3d(0, 2000px, 0);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
}

.fadeInUpBig {
  -webkit-animation-name : fadeInUpBig;
     -moz-animation-name : fadeInUpBig;
       -o-animation-name : fadeInUpBig;
          animation-name : fadeInUpBig;
}

@-webkit-keyframes fadeOut {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
  }
}

@-moz-keyframes fadeOut {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
  }
}

@-o-keyframes fadeOut {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
  }
}

@keyframes fadeOut {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
  }
}

.fadeOut {
  -webkit-animation-name : fadeOut;
     -moz-animation-name : fadeOut;
       -o-animation-name : fadeOut;
          animation-name : fadeOut;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
  }
}

@-moz-keyframes fadeOutDown {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, 100%, 0);
       -moz-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
  }
}

@-o-keyframes fadeOutDown {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, 100%, 0);
       -moz-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name : fadeOutDown;
     -moz-animation-name : fadeOutDown;
       -o-animation-name : fadeOutDown;
          animation-name : fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, 2000px, 0);
            transform : translate3d(0, 2000px, 0);
  }
}

@-moz-keyframes fadeOutDownBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, 2000px, 0);
       -moz-transform : translate3d(0, 2000px, 0);
            transform : translate3d(0, 2000px, 0);
  }
}

@-o-keyframes fadeOutDownBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, 2000px, 0);
            transform : translate3d(0, 2000px, 0);
  }
}

@keyframes fadeOutDownBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, 2000px, 0);
       -moz-transform : translate3d(0, 2000px, 0);
            transform : translate3d(0, 2000px, 0);
  }
}

.fadeOutDownBig {
  -webkit-animation-name : fadeOutDownBig;
     -moz-animation-name : fadeOutDownBig;
       -o-animation-name : fadeOutDownBig;
          animation-name : fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
  }
}

@-moz-keyframes fadeOutLeft {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(-100%, 0, 0);
       -moz-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
  }
}

@-o-keyframes fadeOutLeft {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(-100%, 0, 0);
       -moz-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name : fadeOutLeft;
     -moz-animation-name : fadeOutLeft;
       -o-animation-name : fadeOutLeft;
          animation-name : fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(-2000px, 0, 0);
            transform : translate3d(-2000px, 0, 0);
  }
}

@-moz-keyframes fadeOutLeftBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(-2000px, 0, 0);
       -moz-transform : translate3d(-2000px, 0, 0);
            transform : translate3d(-2000px, 0, 0);
  }
}

@-o-keyframes fadeOutLeftBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(-2000px, 0, 0);
            transform : translate3d(-2000px, 0, 0);
  }
}

@keyframes fadeOutLeftBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(-2000px, 0, 0);
       -moz-transform : translate3d(-2000px, 0, 0);
            transform : translate3d(-2000px, 0, 0);
  }
}

.fadeOutLeftBig {
  -webkit-animation-name : fadeOutLeftBig;
     -moz-animation-name : fadeOutLeftBig;
       -o-animation-name : fadeOutLeftBig;
          animation-name : fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
  }
}

@-moz-keyframes fadeOutRight {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(100%, 0, 0);
       -moz-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
  }
}

@-o-keyframes fadeOutRight {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(100%, 0, 0);
       -moz-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name : fadeOutRight;
     -moz-animation-name : fadeOutRight;
       -o-animation-name : fadeOutRight;
          animation-name : fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(2000px, 0, 0);
            transform : translate3d(2000px, 0, 0);
  }
}

@-moz-keyframes fadeOutRightBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(2000px, 0, 0);
       -moz-transform : translate3d(2000px, 0, 0);
            transform : translate3d(2000px, 0, 0);
  }
}

@-o-keyframes fadeOutRightBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(2000px, 0, 0);
            transform : translate3d(2000px, 0, 0);
  }
}

@keyframes fadeOutRightBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(2000px, 0, 0);
       -moz-transform : translate3d(2000px, 0, 0);
            transform : translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  -webkit-animation-name : fadeOutRightBig;
     -moz-animation-name : fadeOutRightBig;
       -o-animation-name : fadeOutRightBig;
          animation-name : fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
  }
}

@-moz-keyframes fadeOutUp {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, -100%, 0);
       -moz-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
  }
}

@-o-keyframes fadeOutUp {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, -100%, 0);
       -moz-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name : fadeOutUp;
     -moz-animation-name : fadeOutUp;
       -o-animation-name : fadeOutUp;
          animation-name : fadeOutUp;
}

@-webkit-keyframes fadeOutUpBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, -2000px, 0);
            transform : translate3d(0, -2000px, 0);
  }
}

@-moz-keyframes fadeOutUpBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, -2000px, 0);
       -moz-transform : translate3d(0, -2000px, 0);
            transform : translate3d(0, -2000px, 0);
  }
}

@-o-keyframes fadeOutUpBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, -2000px, 0);
            transform : translate3d(0, -2000px, 0);
  }
}

@keyframes fadeOutUpBig {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(0, -2000px, 0);
       -moz-transform : translate3d(0, -2000px, 0);
            transform : translate3d(0, -2000px, 0);
  }
}

.fadeOutUpBig {
  -webkit-animation-name : fadeOutUpBig;
     -moz-animation-name : fadeOutUpBig;
       -o-animation-name : fadeOutUpBig;
          animation-name : fadeOutUpBig;
}

@-webkit-keyframes flip {
  from {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -360deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function : ease-out;
            animation-timing-function : ease-out;
  }
  40% {
    -webkit-transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
            transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function : ease-out;
            animation-timing-function : ease-out;
  }
  50% {
    -webkit-transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
            transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  80% {
    -webkit-transform : perspective(400px) scale3d(0.95, 0.95, 0.95);
            transform : perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  to {
    -webkit-transform : perspective(400px);
            transform : perspective(400px);
    -webkit-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
}

@-moz-keyframes flip {
  from {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -360deg);
       -moz-transform : perspective(400px) rotate3d(0, 1, 0, -360deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function : ease-out;
       -moz-animation-timing-function : ease-out;
            animation-timing-function : ease-out;
  }
  40% {
    -webkit-transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
       -moz-transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
            transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function : ease-out;
       -moz-animation-timing-function : ease-out;
            animation-timing-function : ease-out;
  }
  50% {
    -webkit-transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
       -moz-transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
            transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function : ease-in;
       -moz-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  80% {
    -webkit-transform : perspective(400px) scale3d(0.95, 0.95, 0.95);
       -moz-transform : perspective(400px) scale3d(0.95, 0.95, 0.95);
            transform : perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function : ease-in;
       -moz-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  to {
    -webkit-transform : perspective(400px);
       -moz-transform : perspective(400px);
            transform : perspective(400px);
    -webkit-animation-timing-function : ease-in;
       -moz-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
}

@-o-keyframes flip {
  from {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -360deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function : ease-out;
         -o-animation-timing-function : ease-out;
            animation-timing-function : ease-out;
  }
  40% {
    -webkit-transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
            transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function : ease-out;
         -o-animation-timing-function : ease-out;
            animation-timing-function : ease-out;
  }
  50% {
    -webkit-transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
            transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function : ease-in;
         -o-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  80% {
    -webkit-transform : perspective(400px) scale3d(0.95, 0.95, 0.95);
            transform : perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function : ease-in;
         -o-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  to {
    -webkit-transform : perspective(400px);
            transform : perspective(400px);
    -webkit-animation-timing-function : ease-in;
         -o-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
}

@keyframes flip {
  from {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -360deg);
       -moz-transform : perspective(400px) rotate3d(0, 1, 0, -360deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function : ease-out;
       -moz-animation-timing-function : ease-out;
         -o-animation-timing-function : ease-out;
            animation-timing-function : ease-out;
  }
  40% {
    -webkit-transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
       -moz-transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
            transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function : ease-out;
       -moz-animation-timing-function : ease-out;
         -o-animation-timing-function : ease-out;
            animation-timing-function : ease-out;
  }
  50% {
    -webkit-transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
       -moz-transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
            transform : perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function : ease-in;
       -moz-animation-timing-function : ease-in;
         -o-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  80% {
    -webkit-transform : perspective(400px) scale3d(0.95, 0.95, 0.95);
       -moz-transform : perspective(400px) scale3d(0.95, 0.95, 0.95);
            transform : perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function : ease-in;
       -moz-animation-timing-function : ease-in;
         -o-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  to {
    -webkit-transform : perspective(400px);
       -moz-transform : perspective(400px);
            transform : perspective(400px);
    -webkit-animation-timing-function : ease-in;
       -moz-animation-timing-function : ease-in;
         -o-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
}

.animated.flip {
  -webkit-backface-visibility : visible;
     -moz-backface-visibility : visible;
          backface-visibility : visible;
  -webkit-animation-name : flip;
     -moz-animation-name : flip;
       -o-animation-name : flip;
          animation-name : flip;
}

@-webkit-keyframes flipInX {
  from {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
    opacity : 0;
  }
  40% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  60% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform : perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity : 1;
  }
  80% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform : perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform : perspective(400px);
            transform : perspective(400px);
  }
}

@-moz-keyframes flipInX {
  from {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
       -moz-transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function : ease-in;
       -moz-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
    opacity : 0;
  }
  40% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
       -moz-transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function : ease-in;
       -moz-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  60% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, 10deg);
       -moz-transform : perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform : perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity : 1;
  }
  80% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, -5deg);
       -moz-transform : perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform : perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform : perspective(400px);
       -moz-transform : perspective(400px);
            transform : perspective(400px);
  }
}

@-o-keyframes flipInX {
  from {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function : ease-in;
         -o-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
    opacity : 0;
  }
  40% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function : ease-in;
         -o-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  60% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform : perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity : 1;
  }
  80% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform : perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform : perspective(400px);
            transform : perspective(400px);
  }
}

@keyframes flipInX {
  from {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
       -moz-transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function : ease-in;
       -moz-animation-timing-function : ease-in;
         -o-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
    opacity : 0;
  }
  40% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
       -moz-transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function : ease-in;
       -moz-animation-timing-function : ease-in;
         -o-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  60% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, 10deg);
       -moz-transform : perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform : perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity : 1;
  }
  80% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, -5deg);
       -moz-transform : perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform : perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    -webkit-transform : perspective(400px);
       -moz-transform : perspective(400px);
            transform : perspective(400px);
  }
}

.flipInX {
  -webkit-backface-visibility : visible !important;
     -moz-backface-visibility : visible !important;
          backface-visibility : visible !important;
  -webkit-animation-name : flipInX;
     -moz-animation-name : flipInX;
       -o-animation-name : flipInX;
          animation-name : flipInX;
}

@-webkit-keyframes flipInY {
  from {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
    opacity : 0;
  }
  40% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -20deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  60% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, 10deg);
            transform : perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity : 1;
  }
  80% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -5deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform : perspective(400px);
            transform : perspective(400px);
  }
}

@-moz-keyframes flipInY {
  from {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
       -moz-transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function : ease-in;
       -moz-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
    opacity : 0;
  }
  40% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -20deg);
       -moz-transform : perspective(400px) rotate3d(0, 1, 0, -20deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function : ease-in;
       -moz-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  60% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, 10deg);
       -moz-transform : perspective(400px) rotate3d(0, 1, 0, 10deg);
            transform : perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity : 1;
  }
  80% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -5deg);
       -moz-transform : perspective(400px) rotate3d(0, 1, 0, -5deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform : perspective(400px);
       -moz-transform : perspective(400px);
            transform : perspective(400px);
  }
}

@-o-keyframes flipInY {
  from {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function : ease-in;
         -o-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
    opacity : 0;
  }
  40% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -20deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function : ease-in;
         -o-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  60% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, 10deg);
            transform : perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity : 1;
  }
  80% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -5deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform : perspective(400px);
            transform : perspective(400px);
  }
}

@keyframes flipInY {
  from {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
       -moz-transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function : ease-in;
       -moz-animation-timing-function : ease-in;
         -o-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
    opacity : 0;
  }
  40% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -20deg);
       -moz-transform : perspective(400px) rotate3d(0, 1, 0, -20deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function : ease-in;
       -moz-animation-timing-function : ease-in;
         -o-animation-timing-function : ease-in;
            animation-timing-function : ease-in;
  }
  60% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, 10deg);
       -moz-transform : perspective(400px) rotate3d(0, 1, 0, 10deg);
            transform : perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity : 1;
  }
  80% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -5deg);
       -moz-transform : perspective(400px) rotate3d(0, 1, 0, -5deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -5deg);
  }
  to {
    -webkit-transform : perspective(400px);
       -moz-transform : perspective(400px);
            transform : perspective(400px);
  }
}

.flipInY {
  -webkit-backface-visibility : visible !important;
     -moz-backface-visibility : visible !important;
          backface-visibility : visible !important;
  -webkit-animation-name : flipInY;
     -moz-animation-name : flipInY;
       -o-animation-name : flipInY;
          animation-name : flipInY;
}

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform : perspective(400px);
            transform : perspective(400px);
  }
  30% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity : 1;
  }
  to {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity : 0;
  }
}

@-moz-keyframes flipOutX {
  from {
    -webkit-transform : perspective(400px);
       -moz-transform : perspective(400px);
            transform : perspective(400px);
  }
  30% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
       -moz-transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity : 1;
  }
  to {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
       -moz-transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity : 0;
  }
}

@-o-keyframes flipOutX {
  from {
    -webkit-transform : perspective(400px);
            transform : perspective(400px);
  }
  30% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity : 1;
  }
  to {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity : 0;
  }
}

@keyframes flipOutX {
  from {
    -webkit-transform : perspective(400px);
       -moz-transform : perspective(400px);
            transform : perspective(400px);
  }
  30% {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
       -moz-transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform : perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity : 1;
  }
  to {
    -webkit-transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
       -moz-transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform : perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity : 0;
  }
}

.flipOutX {
  -webkit-animation-name : flipOutX;
     -moz-animation-name : flipOutX;
       -o-animation-name : flipOutX;
          animation-name : flipOutX;
  -webkit-backface-visibility : visible !important;
     -moz-backface-visibility : visible !important;
          backface-visibility : visible !important;
}

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform : perspective(400px);
            transform : perspective(400px);
  }
  30% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -15deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity : 1;
  }
  to {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity : 0;
  }
}

@-moz-keyframes flipOutY {
  from {
    -webkit-transform : perspective(400px);
       -moz-transform : perspective(400px);
            transform : perspective(400px);
  }
  30% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -15deg);
       -moz-transform : perspective(400px) rotate3d(0, 1, 0, -15deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity : 1;
  }
  to {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
       -moz-transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity : 0;
  }
}

@-o-keyframes flipOutY {
  from {
    -webkit-transform : perspective(400px);
            transform : perspective(400px);
  }
  30% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -15deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity : 1;
  }
  to {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity : 0;
  }
}

@keyframes flipOutY {
  from {
    -webkit-transform : perspective(400px);
       -moz-transform : perspective(400px);
            transform : perspective(400px);
  }
  30% {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, -15deg);
       -moz-transform : perspective(400px) rotate3d(0, 1, 0, -15deg);
            transform : perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity : 1;
  }
  to {
    -webkit-transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
       -moz-transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform : perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity : 0;
  }
}

.flipOutY {
  -webkit-backface-visibility : visible !important;
     -moz-backface-visibility : visible !important;
          backface-visibility : visible !important;
  -webkit-animation-name : flipOutY;
     -moz-animation-name : flipOutY;
       -o-animation-name : flipOutY;
          animation-name : flipOutY;
}

@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform : translate3d(100%, 0, 0) skewX(-30deg);
            transform : translate3d(100%, 0, 0) skewX(-30deg);
    opacity : 0;
  }
  60% {
    -webkit-transform : skewX(20deg);
            transform : skewX(20deg);
    opacity : 1;
  }
  80% {
    -webkit-transform : skewX(-5deg);
            transform : skewX(-5deg);
    opacity : 1;
  }
  to {
    -webkit-transform : none;
            transform : none;
    opacity : 1;
  }
}

@-moz-keyframes lightSpeedIn {
  from {
    -webkit-transform : translate3d(100%, 0, 0) skewX(-30deg);
       -moz-transform : translate3d(100%, 0, 0) skewX(-30deg);
            transform : translate3d(100%, 0, 0) skewX(-30deg);
    opacity : 0;
  }
  60% {
    -webkit-transform : skewX(20deg);
       -moz-transform : skewX(20deg);
            transform : skewX(20deg);
    opacity : 1;
  }
  80% {
    -webkit-transform : skewX(-5deg);
       -moz-transform : skewX(-5deg);
            transform : skewX(-5deg);
    opacity : 1;
  }
  to {
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
    opacity : 1;
  }
}

@-o-keyframes lightSpeedIn {
  from {
    -webkit-transform : translate3d(100%, 0, 0) skewX(-30deg);
            transform : translate3d(100%, 0, 0) skewX(-30deg);
    opacity : 0;
  }
  60% {
    -webkit-transform : skewX(20deg);
         -o-transform : skewX(20deg);
            transform : skewX(20deg);
    opacity : 1;
  }
  80% {
    -webkit-transform : skewX(-5deg);
         -o-transform : skewX(-5deg);
            transform : skewX(-5deg);
    opacity : 1;
  }
  to {
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
    opacity : 1;
  }
}

@keyframes lightSpeedIn {
  from {
    -webkit-transform : translate3d(100%, 0, 0) skewX(-30deg);
       -moz-transform : translate3d(100%, 0, 0) skewX(-30deg);
            transform : translate3d(100%, 0, 0) skewX(-30deg);
    opacity : 0;
  }
  60% {
    -webkit-transform : skewX(20deg);
       -moz-transform : skewX(20deg);
         -o-transform : skewX(20deg);
            transform : skewX(20deg);
    opacity : 1;
  }
  80% {
    -webkit-transform : skewX(-5deg);
       -moz-transform : skewX(-5deg);
         -o-transform : skewX(-5deg);
            transform : skewX(-5deg);
    opacity : 1;
  }
  to {
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
    opacity : 1;
  }
}

.lightSpeedIn {
  -webkit-animation-name : lightSpeedIn;
     -moz-animation-name : lightSpeedIn;
       -o-animation-name : lightSpeedIn;
          animation-name : lightSpeedIn;
  -webkit-animation-timing-function : ease-out;
     -moz-animation-timing-function : ease-out;
       -o-animation-timing-function : ease-out;
          animation-timing-function : ease-out;
}

@-webkit-keyframes lightSpeedOut {
  from {
    opacity : 1;
  }
  to {
    -webkit-transform : translate3d(100%, 0, 0) skewX(30deg);
            transform : translate3d(100%, 0, 0) skewX(30deg);
    opacity : 0;
  }
}

@-moz-keyframes lightSpeedOut {
  from {
    opacity : 1;
  }
  to {
    -webkit-transform : translate3d(100%, 0, 0) skewX(30deg);
       -moz-transform : translate3d(100%, 0, 0) skewX(30deg);
            transform : translate3d(100%, 0, 0) skewX(30deg);
    opacity : 0;
  }
}

@-o-keyframes lightSpeedOut {
  from {
    opacity : 1;
  }
  to {
    -webkit-transform : translate3d(100%, 0, 0) skewX(30deg);
            transform : translate3d(100%, 0, 0) skewX(30deg);
    opacity : 0;
  }
}

@keyframes lightSpeedOut {
  from {
    opacity : 1;
  }
  to {
    -webkit-transform : translate3d(100%, 0, 0) skewX(30deg);
       -moz-transform : translate3d(100%, 0, 0) skewX(30deg);
            transform : translate3d(100%, 0, 0) skewX(30deg);
    opacity : 0;
  }
}

.lightSpeedOut {
  -webkit-animation-name : lightSpeedOut;
     -moz-animation-name : lightSpeedOut;
       -o-animation-name : lightSpeedOut;
          animation-name : lightSpeedOut;
  -webkit-animation-timing-function : ease-in;
     -moz-animation-timing-function : ease-in;
       -o-animation-timing-function : ease-in;
          animation-timing-function : ease-in;
}

@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin : center;
            transform-origin : center;
    -webkit-transform : rotate3d(0, 0, 1, -200deg);
            transform : rotate3d(0, 0, 1, -200deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : center;
            transform-origin : center;
    -webkit-transform : none;
            transform : none;
    opacity : 1;
  }
}

@-moz-keyframes rotateIn {
  from {
    -webkit-transform-origin : center;
       -moz-transform-origin : center;
            transform-origin : center;
    -webkit-transform : rotate3d(0, 0, 1, -200deg);
       -moz-transform : rotate3d(0, 0, 1, -200deg);
            transform : rotate3d(0, 0, 1, -200deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : center;
       -moz-transform-origin : center;
            transform-origin : center;
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
    opacity : 1;
  }
}

@-o-keyframes rotateIn {
  from {
    -webkit-transform-origin : center;
         -o-transform-origin : center;
            transform-origin : center;
    -webkit-transform : rotate3d(0, 0, 1, -200deg);
            transform : rotate3d(0, 0, 1, -200deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : center;
         -o-transform-origin : center;
            transform-origin : center;
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
    opacity : 1;
  }
}

@keyframes rotateIn {
  from {
    -webkit-transform-origin : center;
       -moz-transform-origin : center;
         -o-transform-origin : center;
            transform-origin : center;
    -webkit-transform : rotate3d(0, 0, 1, -200deg);
       -moz-transform : rotate3d(0, 0, 1, -200deg);
            transform : rotate3d(0, 0, 1, -200deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : center;
       -moz-transform-origin : center;
         -o-transform-origin : center;
            transform-origin : center;
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
    opacity : 1;
  }
}

.rotateIn {
  -webkit-animation-name : rotateIn;
     -moz-animation-name : rotateIn;
       -o-animation-name : rotateIn;
          animation-name : rotateIn;
}

@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, -45deg);
            transform : rotate3d(0, 0, 1, -45deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : none;
            transform : none;
    opacity : 1;
  }
}

@-moz-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, -45deg);
       -moz-transform : rotate3d(0, 0, 1, -45deg);
            transform : rotate3d(0, 0, 1, -45deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
    opacity : 1;
  }
}

@-o-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, -45deg);
            transform : rotate3d(0, 0, 1, -45deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
    opacity : 1;
  }
}

@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, -45deg);
       -moz-transform : rotate3d(0, 0, 1, -45deg);
            transform : rotate3d(0, 0, 1, -45deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
    opacity : 1;
  }
}

.rotateInDownLeft {
  -webkit-animation-name : rotateInDownLeft;
     -moz-animation-name : rotateInDownLeft;
       -o-animation-name : rotateInDownLeft;
          animation-name : rotateInDownLeft;
}

@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, 45deg);
            transform : rotate3d(0, 0, 1, 45deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : none;
            transform : none;
    opacity : 1;
  }
}

@-moz-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, 45deg);
       -moz-transform : rotate3d(0, 0, 1, 45deg);
            transform : rotate3d(0, 0, 1, 45deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
    opacity : 1;
  }
}

@-o-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, 45deg);
            transform : rotate3d(0, 0, 1, 45deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
    opacity : 1;
  }
}

@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, 45deg);
       -moz-transform : rotate3d(0, 0, 1, 45deg);
            transform : rotate3d(0, 0, 1, 45deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
    opacity : 1;
  }
}

.rotateInDownRight {
  -webkit-animation-name : rotateInDownRight;
     -moz-animation-name : rotateInDownRight;
       -o-animation-name : rotateInDownRight;
          animation-name : rotateInDownRight;
}

@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, 45deg);
            transform : rotate3d(0, 0, 1, 45deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : none;
            transform : none;
    opacity : 1;
  }
}

@-moz-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, 45deg);
       -moz-transform : rotate3d(0, 0, 1, 45deg);
            transform : rotate3d(0, 0, 1, 45deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
    opacity : 1;
  }
}

@-o-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, 45deg);
            transform : rotate3d(0, 0, 1, 45deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
    opacity : 1;
  }
}

@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, 45deg);
       -moz-transform : rotate3d(0, 0, 1, 45deg);
            transform : rotate3d(0, 0, 1, 45deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
    opacity : 1;
  }
}

.rotateInUpLeft {
  -webkit-animation-name : rotateInUpLeft;
     -moz-animation-name : rotateInUpLeft;
       -o-animation-name : rotateInUpLeft;
          animation-name : rotateInUpLeft;
}

@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, -90deg);
            transform : rotate3d(0, 0, 1, -90deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : none;
            transform : none;
    opacity : 1;
  }
}

@-moz-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, -90deg);
       -moz-transform : rotate3d(0, 0, 1, -90deg);
            transform : rotate3d(0, 0, 1, -90deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
    opacity : 1;
  }
}

@-o-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, -90deg);
            transform : rotate3d(0, 0, 1, -90deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
    opacity : 1;
  }
}

@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, -90deg);
       -moz-transform : rotate3d(0, 0, 1, -90deg);
            transform : rotate3d(0, 0, 1, -90deg);
    opacity : 0;
  }
  to {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
    opacity : 1;
  }
}

.rotateInUpRight {
  -webkit-animation-name : rotateInUpRight;
     -moz-animation-name : rotateInUpRight;
       -o-animation-name : rotateInUpRight;
          animation-name : rotateInUpRight;
}

@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin : center;
            transform-origin : center;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : center;
            transform-origin : center;
    -webkit-transform : rotate3d(0, 0, 1, 200deg);
            transform : rotate3d(0, 0, 1, 200deg);
    opacity : 0;
  }
}

@-moz-keyframes rotateOut {
  from {
    -webkit-transform-origin : center;
       -moz-transform-origin : center;
            transform-origin : center;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : center;
       -moz-transform-origin : center;
            transform-origin : center;
    -webkit-transform : rotate3d(0, 0, 1, 200deg);
       -moz-transform : rotate3d(0, 0, 1, 200deg);
            transform : rotate3d(0, 0, 1, 200deg);
    opacity : 0;
  }
}

@-o-keyframes rotateOut {
  from {
    -webkit-transform-origin : center;
         -o-transform-origin : center;
            transform-origin : center;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : center;
         -o-transform-origin : center;
            transform-origin : center;
    -webkit-transform : rotate3d(0, 0, 1, 200deg);
            transform : rotate3d(0, 0, 1, 200deg);
    opacity : 0;
  }
}

@keyframes rotateOut {
  from {
    -webkit-transform-origin : center;
       -moz-transform-origin : center;
         -o-transform-origin : center;
            transform-origin : center;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : center;
       -moz-transform-origin : center;
         -o-transform-origin : center;
            transform-origin : center;
    -webkit-transform : rotate3d(0, 0, 1, 200deg);
       -moz-transform : rotate3d(0, 0, 1, 200deg);
            transform : rotate3d(0, 0, 1, 200deg);
    opacity : 0;
  }
}

.rotateOut {
  -webkit-animation-name : rotateOut;
     -moz-animation-name : rotateOut;
       -o-animation-name : rotateOut;
          animation-name : rotateOut;
}

@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin : left bottom;
            transform-origin : left bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, 45deg);
            transform : rotate3d(0, 0, 1, 45deg);
    opacity : 0;
  }
}

@-moz-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
            transform-origin : left bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, 45deg);
       -moz-transform : rotate3d(0, 0, 1, 45deg);
            transform : rotate3d(0, 0, 1, 45deg);
    opacity : 0;
  }
}

@-o-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, 45deg);
            transform : rotate3d(0, 0, 1, 45deg);
    opacity : 0;
  }
}

@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, 45deg);
       -moz-transform : rotate3d(0, 0, 1, 45deg);
            transform : rotate3d(0, 0, 1, 45deg);
    opacity : 0;
  }
}

.rotateOutDownLeft {
  -webkit-animation-name : rotateOutDownLeft;
     -moz-animation-name : rotateOutDownLeft;
       -o-animation-name : rotateOutDownLeft;
          animation-name : rotateOutDownLeft;
}

@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin : right bottom;
            transform-origin : right bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, -45deg);
            transform : rotate3d(0, 0, 1, -45deg);
    opacity : 0;
  }
}

@-moz-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
            transform-origin : right bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, -45deg);
       -moz-transform : rotate3d(0, 0, 1, -45deg);
            transform : rotate3d(0, 0, 1, -45deg);
    opacity : 0;
  }
}

@-o-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, -45deg);
            transform : rotate3d(0, 0, 1, -45deg);
    opacity : 0;
  }
}

@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, -45deg);
       -moz-transform : rotate3d(0, 0, 1, -45deg);
            transform : rotate3d(0, 0, 1, -45deg);
    opacity : 0;
  }
}

.rotateOutDownRight {
  -webkit-animation-name : rotateOutDownRight;
     -moz-animation-name : rotateOutDownRight;
       -o-animation-name : rotateOutDownRight;
          animation-name : rotateOutDownRight;
}

@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin : left bottom;
            transform-origin : left bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, -45deg);
            transform : rotate3d(0, 0, 1, -45deg);
    opacity : 0;
  }
}

@-moz-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
            transform-origin : left bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, -45deg);
       -moz-transform : rotate3d(0, 0, 1, -45deg);
            transform : rotate3d(0, 0, 1, -45deg);
    opacity : 0;
  }
}

@-o-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, -45deg);
            transform : rotate3d(0, 0, 1, -45deg);
    opacity : 0;
  }
}

@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : left bottom;
       -moz-transform-origin : left bottom;
         -o-transform-origin : left bottom;
            transform-origin : left bottom;
    -webkit-transform : rotate3d(0, 0, 1, -45deg);
       -moz-transform : rotate3d(0, 0, 1, -45deg);
            transform : rotate3d(0, 0, 1, -45deg);
    opacity : 0;
  }
}

.rotateOutUpLeft {
  -webkit-animation-name : rotateOutUpLeft;
     -moz-animation-name : rotateOutUpLeft;
       -o-animation-name : rotateOutUpLeft;
          animation-name : rotateOutUpLeft;
}

@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin : right bottom;
            transform-origin : right bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, 90deg);
            transform : rotate3d(0, 0, 1, 90deg);
    opacity : 0;
  }
}

@-moz-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
            transform-origin : right bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, 90deg);
       -moz-transform : rotate3d(0, 0, 1, 90deg);
            transform : rotate3d(0, 0, 1, 90deg);
    opacity : 0;
  }
}

@-o-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, 90deg);
            transform : rotate3d(0, 0, 1, 90deg);
    opacity : 0;
  }
}

@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    opacity : 1;
  }
  to {
    -webkit-transform-origin : right bottom;
       -moz-transform-origin : right bottom;
         -o-transform-origin : right bottom;
            transform-origin : right bottom;
    -webkit-transform : rotate3d(0, 0, 1, 90deg);
       -moz-transform : rotate3d(0, 0, 1, 90deg);
            transform : rotate3d(0, 0, 1, 90deg);
    opacity : 0;
  }
}

.rotateOutUpRight {
  -webkit-animation-name : rotateOutUpRight;
     -moz-animation-name : rotateOutUpRight;
       -o-animation-name : rotateOutUpRight;
          animation-name : rotateOutUpRight;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin : top left;
            transform-origin : top left;
    -webkit-animation-timing-function : ease-in-out;
            animation-timing-function : ease-in-out;
  }
  20%, 60% {
    -webkit-transform : rotate3d(0, 0, 1, 80deg);
            transform : rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin : top left;
            transform-origin : top left;
    -webkit-animation-timing-function : ease-in-out;
            animation-timing-function : ease-in-out;
  }
  40%, 80% {
    -webkit-transform : rotate3d(0, 0, 1, 60deg);
            transform : rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin : top left;
            transform-origin : top left;
    -webkit-animation-timing-function : ease-in-out;
            animation-timing-function : ease-in-out;
    opacity : 1;
  }
  to {
    -webkit-transform : translate3d(0, 700px, 0);
            transform : translate3d(0, 700px, 0);
    opacity : 0;
  }
}

@-moz-keyframes hinge {
  0% {
    -webkit-transform-origin : top left;
       -moz-transform-origin : top left;
            transform-origin : top left;
    -webkit-animation-timing-function : ease-in-out;
       -moz-animation-timing-function : ease-in-out;
            animation-timing-function : ease-in-out;
  }
  20%, 60% {
    -webkit-transform : rotate3d(0, 0, 1, 80deg);
       -moz-transform : rotate3d(0, 0, 1, 80deg);
            transform : rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin : top left;
       -moz-transform-origin : top left;
            transform-origin : top left;
    -webkit-animation-timing-function : ease-in-out;
       -moz-animation-timing-function : ease-in-out;
            animation-timing-function : ease-in-out;
  }
  40%, 80% {
    -webkit-transform : rotate3d(0, 0, 1, 60deg);
       -moz-transform : rotate3d(0, 0, 1, 60deg);
            transform : rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin : top left;
       -moz-transform-origin : top left;
            transform-origin : top left;
    -webkit-animation-timing-function : ease-in-out;
       -moz-animation-timing-function : ease-in-out;
            animation-timing-function : ease-in-out;
    opacity : 1;
  }
  to {
    -webkit-transform : translate3d(0, 700px, 0);
       -moz-transform : translate3d(0, 700px, 0);
            transform : translate3d(0, 700px, 0);
    opacity : 0;
  }
}

@-o-keyframes hinge {
  0% {
    -webkit-transform-origin : top left;
         -o-transform-origin : top left;
            transform-origin : top left;
    -webkit-animation-timing-function : ease-in-out;
         -o-animation-timing-function : ease-in-out;
            animation-timing-function : ease-in-out;
  }
  20%, 60% {
    -webkit-transform : rotate3d(0, 0, 1, 80deg);
            transform : rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin : top left;
         -o-transform-origin : top left;
            transform-origin : top left;
    -webkit-animation-timing-function : ease-in-out;
         -o-animation-timing-function : ease-in-out;
            animation-timing-function : ease-in-out;
  }
  40%, 80% {
    -webkit-transform : rotate3d(0, 0, 1, 60deg);
            transform : rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin : top left;
         -o-transform-origin : top left;
            transform-origin : top left;
    -webkit-animation-timing-function : ease-in-out;
         -o-animation-timing-function : ease-in-out;
            animation-timing-function : ease-in-out;
    opacity : 1;
  }
  to {
    -webkit-transform : translate3d(0, 700px, 0);
            transform : translate3d(0, 700px, 0);
    opacity : 0;
  }
}

@keyframes hinge {
  0% {
    -webkit-transform-origin : top left;
       -moz-transform-origin : top left;
         -o-transform-origin : top left;
            transform-origin : top left;
    -webkit-animation-timing-function : ease-in-out;
       -moz-animation-timing-function : ease-in-out;
         -o-animation-timing-function : ease-in-out;
            animation-timing-function : ease-in-out;
  }
  20%, 60% {
    -webkit-transform : rotate3d(0, 0, 1, 80deg);
       -moz-transform : rotate3d(0, 0, 1, 80deg);
            transform : rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin : top left;
       -moz-transform-origin : top left;
         -o-transform-origin : top left;
            transform-origin : top left;
    -webkit-animation-timing-function : ease-in-out;
       -moz-animation-timing-function : ease-in-out;
         -o-animation-timing-function : ease-in-out;
            animation-timing-function : ease-in-out;
  }
  40%, 80% {
    -webkit-transform : rotate3d(0, 0, 1, 60deg);
       -moz-transform : rotate3d(0, 0, 1, 60deg);
            transform : rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin : top left;
       -moz-transform-origin : top left;
         -o-transform-origin : top left;
            transform-origin : top left;
    -webkit-animation-timing-function : ease-in-out;
       -moz-animation-timing-function : ease-in-out;
         -o-animation-timing-function : ease-in-out;
            animation-timing-function : ease-in-out;
    opacity : 1;
  }
  to {
    -webkit-transform : translate3d(0, 700px, 0);
       -moz-transform : translate3d(0, 700px, 0);
            transform : translate3d(0, 700px, 0);
    opacity : 0;
  }
}

.hinge {
  -webkit-animation-name : hinge;
     -moz-animation-name : hinge;
       -o-animation-name : hinge;
          animation-name : hinge;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollIn {
  from {
    opacity : 0;
    -webkit-transform : translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
            transform : translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
            transform : none;
  }
}

@-moz-keyframes rollIn {
  from {
    opacity : 0;
    -webkit-transform : translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
       -moz-transform : translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
            transform : translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
            transform : none;
  }
}

@-o-keyframes rollIn {
  from {
    opacity : 0;
    -webkit-transform : translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
            transform : translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
         -o-transform : none;
            transform : none;
  }
}

@keyframes rollIn {
  from {
    opacity : 0;
    -webkit-transform : translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
       -moz-transform : translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
            transform : translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
  to {
    opacity : 1;
    -webkit-transform : none;
       -moz-transform : none;
         -o-transform : none;
            transform : none;
  }
}

.rollIn {
  -webkit-animation-name : rollIn;
     -moz-animation-name : rollIn;
       -o-animation-name : rollIn;
          animation-name : rollIn;
}

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@-webkit-keyframes rollOut {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
            transform : translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}

@-moz-keyframes rollOut {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
       -moz-transform : translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
            transform : translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}

@-o-keyframes rollOut {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
            transform : translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}

@keyframes rollOut {
  from {
    opacity : 1;
  }
  to {
    opacity : 0;
    -webkit-transform : translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
       -moz-transform : translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
            transform : translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}

.rollOut {
  -webkit-animation-name : rollOut;
     -moz-animation-name : rollOut;
       -o-animation-name : rollOut;
          animation-name : rollOut;
}

@-webkit-keyframes zoomIn {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity : 1;
  }
}

@-moz-keyframes zoomIn {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
       -moz-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity : 1;
  }
}

@-o-keyframes zoomIn {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity : 1;
  }
}

@keyframes zoomIn {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
       -moz-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity : 1;
  }
}

.zoomIn {
  -webkit-animation-name : zoomIn;
     -moz-animation-name : zoomIn;
       -o-animation-name : zoomIn;
          animation-name : zoomIn;
}

@-webkit-keyframes zoomInDown {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@-moz-keyframes zoomInDown {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
       -moz-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
       -moz-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
       -moz-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@-o-keyframes zoomInDown {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
         -o-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
         -o-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInDown {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
       -moz-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
       -moz-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
         -o-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
       -moz-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
         -o-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInDown {
  -webkit-animation-name : zoomInDown;
     -moz-animation-name : zoomInDown;
       -o-animation-name : zoomInDown;
          animation-name : zoomInDown;
}

@-webkit-keyframes zoomInLeft {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@-moz-keyframes zoomInLeft {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
       -moz-transform : scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
       -moz-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
       -moz-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@-o-keyframes zoomInLeft {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
         -o-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
         -o-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInLeft {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
       -moz-transform : scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
       -moz-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
         -o-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
       -moz-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
         -o-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInLeft {
  -webkit-animation-name : zoomInLeft;
     -moz-animation-name : zoomInLeft;
       -o-animation-name : zoomInLeft;
          animation-name : zoomInLeft;
}

@-webkit-keyframes zoomInRight {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@-moz-keyframes zoomInRight {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
       -moz-transform : scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
       -moz-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
       -moz-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@-o-keyframes zoomInRight {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
         -o-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
         -o-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInRight {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
       -moz-transform : scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
       -moz-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
         -o-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
       -moz-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
         -o-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInRight {
  -webkit-animation-name : zoomInRight;
     -moz-animation-name : zoomInRight;
       -o-animation-name : zoomInRight;
          animation-name : zoomInRight;
}

@-webkit-keyframes zoomInUp {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@-moz-keyframes zoomInUp {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
       -moz-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
       -moz-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
       -moz-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@-o-keyframes zoomInUp {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
         -o-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
         -o-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInUp {
  from {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
       -moz-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
       -moz-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
         -o-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  60% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
       -moz-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
         -o-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInUp {
  -webkit-animation-name : zoomInUp;
     -moz-animation-name : zoomInUp;
       -o-animation-name : zoomInUp;
          animation-name : zoomInUp;
}

@-webkit-keyframes zoomOut {
  from {
    opacity : 1;
  }
  50% {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity : 0;
  }
}

@-moz-keyframes zoomOut {
  from {
    opacity : 1;
  }
  50% {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
       -moz-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity : 0;
  }
}

@-o-keyframes zoomOut {
  from {
    opacity : 1;
  }
  50% {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity : 0;
  }
}

@keyframes zoomOut {
  from {
    opacity : 1;
  }
  50% {
    opacity : 0;
    -webkit-transform : scale3d(0.3, 0.3, 0.3);
       -moz-transform : scale3d(0.3, 0.3, 0.3);
            transform : scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity : 0;
  }
}

.zoomOut {
  -webkit-animation-name : zoomOut;
     -moz-animation-name : zoomOut;
       -o-animation-name : zoomOut;
          animation-name : zoomOut;
}

@-webkit-keyframes zoomOutDown {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin : center bottom;
            transform-origin : center bottom;
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@-moz-keyframes zoomOutDown {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
       -moz-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
       -moz-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin : center bottom;
       -moz-transform-origin : center bottom;
            transform-origin : center bottom;
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
       -moz-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@-o-keyframes zoomOutDown {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
         -o-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin : center bottom;
         -o-transform-origin : center bottom;
            transform-origin : center bottom;
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
         -o-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomOutDown {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
       -moz-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
         -o-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
       -moz-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin : center bottom;
       -moz-transform-origin : center bottom;
         -o-transform-origin : center bottom;
            transform-origin : center bottom;
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
       -moz-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
         -o-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomOutDown {
  -webkit-animation-name : zoomOutDown;
     -moz-animation-name : zoomOutDown;
       -o-animation-name : zoomOutDown;
          animation-name : zoomOutDown;
}

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : scale(0.1) translate3d(-2000px, 0, 0);
            transform : scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin : left center;
            transform-origin : left center;
  }
}

@-moz-keyframes zoomOutLeft {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : scale(0.1) translate3d(-2000px, 0, 0);
       -moz-transform : scale(0.1) translate3d(-2000px, 0, 0);
            transform : scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin : left center;
       -moz-transform-origin : left center;
            transform-origin : left center;
  }
}

@-o-keyframes zoomOutLeft {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : scale(0.1) translate3d(-2000px, 0, 0);
            transform : scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin : left center;
         -o-transform-origin : left center;
            transform-origin : left center;
  }
}

@keyframes zoomOutLeft {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : scale(0.1) translate3d(-2000px, 0, 0);
       -moz-transform : scale(0.1) translate3d(-2000px, 0, 0);
            transform : scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin : left center;
       -moz-transform-origin : left center;
         -o-transform-origin : left center;
            transform-origin : left center;
  }
}

.zoomOutLeft {
  -webkit-animation-name : zoomOutLeft;
     -moz-animation-name : zoomOutLeft;
       -o-animation-name : zoomOutLeft;
          animation-name : zoomOutLeft;
}

@-webkit-keyframes zoomOutRight {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : scale(0.1) translate3d(2000px, 0, 0);
            transform : scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin : right center;
            transform-origin : right center;
  }
}

@-moz-keyframes zoomOutRight {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : scale(0.1) translate3d(2000px, 0, 0);
       -moz-transform : scale(0.1) translate3d(2000px, 0, 0);
            transform : scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin : right center;
       -moz-transform-origin : right center;
            transform-origin : right center;
  }
}

@-o-keyframes zoomOutRight {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : scale(0.1) translate3d(2000px, 0, 0);
            transform : scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin : right center;
         -o-transform-origin : right center;
            transform-origin : right center;
  }
}

@keyframes zoomOutRight {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }
  to {
    opacity : 0;
    -webkit-transform : scale(0.1) translate3d(2000px, 0, 0);
       -moz-transform : scale(0.1) translate3d(2000px, 0, 0);
            transform : scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin : right center;
       -moz-transform-origin : right center;
         -o-transform-origin : right center;
            transform-origin : right center;
  }
}

.zoomOutRight {
  -webkit-animation-name : zoomOutRight;
     -moz-animation-name : zoomOutRight;
       -o-animation-name : zoomOutRight;
          animation-name : zoomOutRight;
}

@-webkit-keyframes zoomOutUp {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin : center bottom;
            transform-origin : center bottom;
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@-moz-keyframes zoomOutUp {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
       -moz-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
       -moz-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin : center bottom;
       -moz-transform-origin : center bottom;
            transform-origin : center bottom;
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
       -moz-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@-o-keyframes zoomOutUp {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
         -o-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin : center bottom;
         -o-transform-origin : center bottom;
            transform-origin : center bottom;
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
         -o-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomOutUp {
  40% {
    opacity : 1;
    -webkit-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
       -moz-transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
            transform : scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
       -moz-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
         -o-animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function : cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  to {
    opacity : 0;
    -webkit-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
       -moz-transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
            transform : scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin : center bottom;
       -moz-transform-origin : center bottom;
         -o-transform-origin : center bottom;
            transform-origin : center bottom;
    -webkit-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
       -moz-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
         -o-animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function : cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomOutUp {
  -webkit-animation-name : zoomOutUp;
     -moz-animation-name : zoomOutUp;
       -o-animation-name : zoomOutUp;
          animation-name : zoomOutUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@-moz-keyframes slideInDown {
  from {
    -webkit-transform : translate3d(0, -100%, 0);
       -moz-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@-o-keyframes slideInDown {
  from {
    -webkit-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform : translate3d(0, -100%, 0);
       -moz-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name : slideInDown;
     -moz-animation-name : slideInDown;
       -o-animation-name : slideInDown;
          animation-name : slideInDown;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@-moz-keyframes slideInLeft {
  from {
    -webkit-transform : translate3d(-100%, 0, 0);
       -moz-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@-o-keyframes slideInLeft {
  from {
    -webkit-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform : translate3d(-100%, 0, 0);
       -moz-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name : slideInLeft;
     -moz-animation-name : slideInLeft;
       -o-animation-name : slideInLeft;
          animation-name : slideInLeft;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@-moz-keyframes slideInRight {
  from {
    -webkit-transform : translate3d(100%, 0, 0);
       -moz-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@-o-keyframes slideInRight {
  from {
    -webkit-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform : translate3d(100%, 0, 0);
       -moz-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name : slideInRight;
     -moz-animation-name : slideInRight;
       -o-animation-name : slideInRight;
          animation-name : slideInRight;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@-moz-keyframes slideInUp {
  from {
    -webkit-transform : translate3d(0, 100%, 0);
       -moz-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@-o-keyframes slideInUp {
  from {
    -webkit-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform : translate3d(0, 100%, 0);
       -moz-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
    visibility : visible;
  }
  to {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name : slideInUp;
     -moz-animation-name : slideInUp;
       -o-animation-name : slideInUp;
          animation-name : slideInUp;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
  }
}

@-moz-keyframes slideOutDown {
  from {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(0, 100%, 0);
       -moz-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
  }
}

@-o-keyframes slideOutDown {
  from {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
  }
}

@keyframes slideOutDown {
  from {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(0, 100%, 0);
       -moz-transform : translate3d(0, 100%, 0);
            transform : translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  -webkit-animation-name : slideOutDown;
     -moz-animation-name : slideOutDown;
       -o-animation-name : slideOutDown;
          animation-name : slideOutDown;
}

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
  }
}

@-moz-keyframes slideOutLeft {
  from {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(-100%, 0, 0);
       -moz-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
  }
}

@-o-keyframes slideOutLeft {
  from {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(-100%, 0, 0);
       -moz-transform : translate3d(-100%, 0, 0);
            transform : translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  -webkit-animation-name : slideOutLeft;
     -moz-animation-name : slideOutLeft;
       -o-animation-name : slideOutLeft;
          animation-name : slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
  }
}

@-moz-keyframes slideOutRight {
  from {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(100%, 0, 0);
       -moz-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
  }
}

@-o-keyframes slideOutRight {
  from {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(100%, 0, 0);
       -moz-transform : translate3d(100%, 0, 0);
            transform : translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  -webkit-animation-name : slideOutRight;
     -moz-animation-name : slideOutRight;
       -o-animation-name : slideOutRight;
          animation-name : slideOutRight;
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
  }
}

@-moz-keyframes slideOutUp {
  from {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(0, -100%, 0);
       -moz-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
  }
}

@-o-keyframes slideOutUp {
  from {
    -webkit-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform : translate3d(0, 0, 0);
       -moz-transform : translate3d(0, 0, 0);
            transform : translate3d(0, 0, 0);
  }
  to {
    visibility : hidden;
    -webkit-transform : translate3d(0, -100%, 0);
       -moz-transform : translate3d(0, -100%, 0);
            transform : translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  -webkit-animation-name : slideOutUp;
     -moz-animation-name : slideOutUp;
       -o-animation-name : slideOutUp;
          animation-name : slideOutUp;
}